import { CommonModule } from '@angular/common';
import { Component, inject, model, effect } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Observable, tap, catchError, throwError } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMagnifyingGlass, faGear } from '@fortawesome/pro-regular-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalFiltersService, LookupService, AuthService } from '../../../shared/services';
import { AccessTokenModel } from '../../../shared/models/auth.models';
import { LookupView, LeagueYearLookupView } from '../../../shared/models';

import { LookupCodes, Messages, AppPermissionCodes } from '@app/constants';

import { MenuModalComponent } from '../menu-modal/menu-modal.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterLink, RouterLinkActive, FormsModule,
		FontAwesomeModule, NgbDropdownModule,
		MenuModalComponent
	]
})
export class HeaderComponent {
	protected AppPermissionCodes = AppPermissionCodes;

	protected searchIcon = faMagnifyingGlass;
	protected settingsIcon = faGear;

	protected userProfile?: AccessTokenModel;
	protected userInitials?: string;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	private modalService: NgbModal = inject(NgbModal);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	private lookupService: LookupService = inject(LookupService);
	protected authService: AuthService = inject(AuthService);

	// lookups
	protected leagueYearsLookup$!: Observable<LeagueYearLookupView[]>;
	protected leagueYearsLookupLoading = true;

	// internal state
	protected selectedLeagueYear = model<LeagueYearLookupView | null>(null);

	constructor() {
		this.initializeLookups();

		this.userProfile = this.authService.getUserProfile();
		this.userInitials = (this.userProfile?.given_name?.charAt(0) ?? '') + (this.userProfile?.family_name?.charAt(0) ?? '');

		// effects
		effect(() => {
			const leagueYear = this.selectedLeagueYear();
			if (leagueYear?.id && leagueYear?.name) {
				this.globalFiltersService.setLeagueYear(leagueYear.id, leagueYear.name);
			}
		});
	}

	private initializeLookups() {
		this.leagueYearsLookup$ = this.lookupService.getLookup<LeagueYearLookupView>(LookupCodes.LeagueYears)
			.pipe(
				tap(leagueYears => {
					setTimeout(() => this.leagueYearsLookupLoading = false, 0);
					this.selectedLeagueYear.set(leagueYears.find(ly => ly.current) ?? null);
				}),
				catchError(err => {
					setTimeout(() => this.leagueYearsLookupLoading = false, 0);
					return this.showLookupErrorToast('League Years', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	onShowMenu() {
		this.modalService.open(MenuModalComponent, { fullscreen: true, animation: true });
	}
}

