<div class="container pt-3 pt-sm-4">
	<div class="d-flex align-items-center justify-content-between mb-3">
		<h1 class="mb-0">
			{{ postID ? 'Edit Post' : 'New Post' }}
		</h1>
		<button class="btn btn-lg me-n3" (click)="onCancel()"><app-close-icon /></button>
	</div>

	@if (postLoading) {
	<app-card-placeholder class="mb-4" [bodyRowItemCounts]="[[1],[1]]" />
	<app-card-placeholder class="mb-4" [bodyRowItemCounts]="[[3]]" />
	} @else if (postError) {
	<app-error class="mb-4" message="Error loading post details" (retry)="onLoadPost()" />
	} @else if (!post) {
	<app-error class="mb-4" message="Error loading post details (cannot find)" (retry)="onLoadPost()" />
	} @else {
	<form>
		<fieldset [disabled]="postSaving || postLoading || postError">
			<div class="card mb-4">
				<div class="card-body">
					<div class="mb-3">
						<input id="titleField" name="titleField" class="form-control form-control-lg fw-bold"
							type="text" placeholder="Post title..." aria-label="Post title" autofocus
							[(ngModel)]="post.title">
					</div>
					<quill-editor id="contentEditor" name="contentEditor" [styles]="{'min-height': '200px'}"
						class="w-100" format="html" placeholder="Post content..." [modules]="modules"
						[(ngModel)]="post.content" [readOnly]="postSaving || postLoading || postError"></quill-editor>
				</div>
			</div>

			<div class="card mb-4">
				<div class="card-body">
					<div class="row g-2">
						<div [class.col-auto]="post.isGlobal" [class.col-sm-14]="!post.isGlobal">
							<div class="row g-2 align-items-center">
								<div class="col-auto">
									<label class="col-form-label" for="regionsField">Visible in regions</label>
								</div>
								<div class="col-auto d-flex align-items-center">
									<div class="form-check form-switch">
										<input id="everywhereSwitch" name="everywhereSwitch" class="form-check-input"
											type="checkbox" role="switch" [(ngModel)]="post.isGlobal"
											(change)="onGlobalChange()">
										<label class="form-check-label" for="everywhereSwitch">Everywhere</label>
									</div>
								</div>
								@if (!post.isGlobal) {
								<div class="col-24 col-sm-auto">
									<!-- [compareWith]="compareRegions" -->
									<ng-select labelForId="regionsField" name="regionsField"
										[(ngModel)]="visibleInRegions" [loading]="regionsLookupLoading"
										[hideSelected]="true" [multiple]="true" [searchable]="false"
										[closeOnSelect]="false" [readonly]="postSaving || regionsLookupLoading"
										(change)="onVisibleInRegionsChange()">
										@for (region of (regionsLookup$ | async); track region.id) {
										<ng-option [value]="region">{{ region.name }}</ng-option>
										}
									</ng-select>
								</div>
								}
							</div>
						</div>
						<div class="col-24 col-sm-auto d-flex align-items-center">
							<div class="form-check form-switch">
								<input id="pinPostSwitch" name="pinPostSwitch" class="form-check-input" type="checkbox"
									role="switch" [(ngModel)]="post.isPinned">
								<label class="form-check-label" for="pinPostSwitch">Pin post</label>
							</div>
						</div>
						<div class="col-24 col-sm-auto d-flex align-items-center">
							<div class="form-check form-switch">
								<input id="allowCommentsSwitch" name="allowCommentsSwitch" class="form-check-input"
									type="checkbox" role="switch" [(ngModel)]="post.allowComments">
								<label class="form-check-label" for="allowCommentsSwitch">Allow comments</label>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="card-footer">
						<pre>{{ post | json }}</pre>
					</div> -->
			</div>

			<div class="d-flex align-items-center justify-content-center justify-content-sm-end gap-2 mb-5">
				<button class="btn btn-lg btn-outline-secondary" (click)="onCancel()">Cancel</button>
				<button class="btn btn-lg btn-primary" (click)="onSave()">
					Save Post
					@if (postSaving) { <app-spinner class="ms-2" /> }
				</button>
			</div>
		</fieldset>
	</form>
	}
</div>
