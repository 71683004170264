import { Injectable, inject } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';

import { Observable, ReplaySubject, catchError, from, of, throwError, lastValueFrom, switchMap } from 'rxjs';

import { AuthService } from '../../shared/services/auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	// private http: HttpClient = inject(HttpClient);
	private router: Router = inject(Router);
	private authService: AuthService = inject(AuthService);

	constructor() { }

	isRefreshingAuthToken = false;

	private get authHeader() {
		return new HttpHeaders({
			Authorization: `Bearer ${this.authService.accessToken}`
		});
	}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// // Usage: `new HttpContext().set(BYPASS_AUTH_INTERCEPTOR, true);`
		// if (req.context.get(BYPASS_AUTH_INTERCEPTOR) === true) {
		// 	return next.handle(req);
		// }

		// apply this logic only for backend (starts with `/api/`)
		if (!req.url?.includes('/api/')) {
			return next.handle(req);
		}

		// console.log(`HTTP => Adding auth header to request to ${req.url}`);

		// clone immutable request and modify header
		req = req.clone({ headers: this.authHeader });
		// req = req.clone({ headers: new HttpHeaders({
		// 	Authorization: `Bearer ${this.authService.accessToken}BAD`
		// }) });

		// TODO: PROACTIVELY CHECK FOR TOKEN EXPIRATION AND REFRESH
		// AND SEPARATE REFRESH TOKEN LOGIC INTO ERROR INTERCEPTOR
		// (https://dev.to/brainiacneit/single-page-application-authentication-and-authorization-in-agularjs-54i2)
		// return the cloned request and try to catch errors
		return next.handle(req).pipe(
			catchError((err: HttpErrorResponse) => {
				console.log(`HTTP => Failed request (${err.status})..`);

				const refreshToken = this.authService.refreshToken;

				// if the error status code is 401 (Unauthorized) and a refreshToken
				// exists then try to request a new access token
				if (
					err.status == HttpStatusCode.Unauthorized &&
					refreshToken &&
					!this.isRefreshingAuthToken // to prevent multiple calls
				) {
					console.log(`HTTP => Refreshing access token...`);

					this.isRefreshingAuthToken = true;

					return from(this.authService.refreshAccessToken()).pipe(
						switchMap(() => {
							console.log(`HTTP => Refreshed access token! Retrying original request...`);

							this.isRefreshingAuthToken = false;
							req = req.clone({ headers: this.authHeader });
							return next.handle(req);
						}),
						catchError(refreshErr => {
							console.log(`HTTP => Failed to refresh access token!`);

							this.isRefreshingAuthToken = false;

							// TODO: REVIEW THIS
							this.authService.redirectUrl = this.getCurrentRouteSegments();
							this.authService.logout();
							this.router.navigateByUrl('/login');

							return throwError(() => refreshErr);
						})
					);
				}

				console.log(`HTTP => Already refreshing token (${this.isRefreshingAuthToken}) or non-auth error (${err.status}).`);

				// if everything else fails reset and throw an error.
				this.isRefreshingAuthToken = false;
				return throwError(() => err);
			})
		);
	}

	private getCurrentRouteSegments(): UrlSegment[] {
		let route = this.router.routerState.root;
		let urlSegments: UrlSegment[] = [];

		while (route.firstChild) {
			route = route.firstChild;
		}

		if (route.snapshot.url.length) {
			urlSegments = route.snapshot.url;
		}

		return urlSegments;
	}
}
