<div id="loginContainer" class="container-fluid vh-100">
	<img id="loginBackgroundImage" class="d-none d-md-block"
		src="/assets/images/login-background{{ backgroundImageIndex }}.jpg" alt="Login Background Image">
	<button class="btn btn-sm position-absolute top-0 end-0" (click)="onNextBackground()">>></button>

	<form #loginForm="ngForm" class="h-100" (ngSubmit)="onSubmit()">
		<fieldset class="h-100" [disabled]="processing">
			<div class="row h-100">
				<div id="loginFormPanel"
					class="col-md-16 col-lg-12 col-xl-10 col-xxl-8 d-flex flex-column justify-content-sm-center align-items-center px-4 pt-5 pt-sm-0 px-md-5">
					<div class="mb-5">
						<img src="/assets/images/logo-vertical-dark.svg?cache=1" alt="MLB RPM logo" width="300">
					</div>

					@switch (loginState) {
					@case (LoginStates.SignIn) {
					<div class="mb-3 w-100">
						<label class="form-label text-secondary" for="emailInput">Email</label>
						<input id="emailInput" name="emailInput"
							class="form-control form-control-lg bg-transparent border-secondary text-white" type="email"
							placeholder="name@example.com" autofocus required [(ngModel)]="username">
					</div>
					<div class="mb-3 w-100">
						<label class="form-label text-secondary" for="passwordInput">Password</label>
						<input id="passwordInput" name="passwordInput"
							class="form-control form-control-lg bg-transparent border-secondary text-white"
							type="password" required [(ngModel)]="password">
					</div>
					<div class="row w-100 mb-5">
						<div class="col ps-0">
							<div class="form-check">
								<input id="rememberCheckbox" class="form-check-input bg-transparent border-secondary"
									type="checkbox" value="">
								<label class="form-check-label text-secondary" for="rememberCheckbox">
									Remember me
								</label>
							</div>
						</div>
						<div class="col pe-0 text-end">
							<a class="btn btn-link p-0" (click)="setLoginState(LoginStates.SendVerificationCode)">Forgot
								Password?</a>
						</div>
					</div>
					<div class="d-grid w-100">
						<button class="btn btn-lg btn-primary" type="submit">
							<fa-icon class="me-2" [icon]="signinIcon" /> Sign In
							@if (processing) { <app-spinner class="ms-1" /> }
						</button>
					</div>
					}
					@case (LoginStates.SendVerificationCode) {
					<div class="mb-5 w-100">
						<label class="form-label text-secondary" for="emailInputVerification">Email</label>
						<input id="emailInputVerification" name="emailInputVerification"
							class="form-control form-control-lg bg-transparent border-secondary text-white" type="email"
							placeholder="name@example.com" autofocus required [(ngModel)]="username">
					</div>
					<div class="d-grid w-100 mb-3">
						<button class="btn btn-lg btn-primary" type="submit">
							Request Password Reset
							@if (!processing) { <app-details-icon class="ms-2" /> } @else { <app-spinner class="ms-1" />
							}
						</button>
					</div>
					<div class="d-grid w-100 mb-3">
						<button class="btn btn-lg btn-outline-secondary" type="button" (click)="setLoginState()">
							Back to Sign In
						</button>
					</div>
					<div class="tiny text-secondary text-center w-100">
						This site is protected by reCAPTCHA and the Google
						<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
						<a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
					</div>
					}
					@case (LoginStates.VerifyCode) {
					<div class="alert alert-dark w-100" role="alert">
						<app-info-icon class="me-2" /> <span>Click the link in the <strong>Password Reset</strong> email
							or copy and
							paste the verification code here.</span>
					</div>
					<div class="mb-3 w-100">
						<label class="form-label text-secondary" for="emailInputForCode">Email</label>
						<input id="emailInputForCode" name="emailInputForCode"
							class="form-control form-control-lg bg-transparent border-secondary text-white" type="email"
							placeholder="name@example.com" autofocus required [(ngModel)]="username">
					</div>
					<div class="mb-5 w-100">
						<label class="form-label text-secondary" for="verificationCode">Verification code</label>
						<input id="verificationCode" name="verificationCode"
							class="form-control form-control-lg bg-transparent border-secondary text-white" type="email"
							placeholder="Verification code" required [(ngModel)]="code">
					</div>
					<div class="d-grid w-100 mb-3">
						<button class="btn btn-lg btn-primary" type="submit">
							Complete Password Reset
							@if (!processing) { <app-details-icon class="ms-2" /> }
							@else { <app-spinner class="ms-1" /> }
						</button>
					</div>
					<div class="d-grid w-100">
						<button class="btn btn-lg btn-outline-secondary" type="button" (click)="setLoginState()">
							Back to Sign In
						</button>
					</div>
					}
					@case (LoginStates.ChangePassword) {
					<div class="mb-3 w-100">
						<label class="form-label text-secondary" for="newPasswordInput">New password</label>
						<input id="newPasswordInput" name="newPasswordInput"
							class="form-control form-control-lg bg-transparent border-secondary text-white"
							type="password" placeholder="New password" autofocus required [(ngModel)]="passwordNew">
					</div>
					<div class="mb-5 w-100 field-required">
						<label class="form-label text-secondary" for="changePasswordConfirmInput">Confirm
							password</label>
						<input id="changePasswordConfirmInput" name="changePasswordConfirmInput"
							class="form-control form-control-lg bg-transparent border-secondary text-white"
							type="password" placeholder="Confirm password" required [(ngModel)]="passwordConfirm"
							aria-describedby="passwordHelpBlock">
						<div id="passwordHelpBlock" style="height: 24px" class="mt-2">
							@if (passwordNew !== passwordConfirm) {
							<div class="form-text text-danger">
								Password does not match.
							</div>
							}
						</div>
					</div>

					<div class="d-grid w-100 mb-3">
						<button class="btn btn-lg btn-primary" type="submit" [disabled]="!isValidPassword()">
							<fa-icon class="me-2" [icon]="syncIcon" />
							Change Password
							@if (processing) { <app-spinner class="ms-1" /> }
						</button>
					</div>
					<div class="d-grid w-100">
						<button class="btn btn-lg btn-outline-secondary" type="button" (click)="setLoginState()">
							Back to Sign In
						</button>
					</div>
					}
					}
				</div>
			</div>
		</fieldset>
	</form>
</div>
