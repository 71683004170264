import { CommonModule } from '@angular/common';
import { Component, inject, model, effect } from '@angular/core';
import { RouterLink, RouterLinkActive, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Observable, tap, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DetailsIconComponent } from '@app/shared/components';
import { GlobalFiltersService, LookupService, AuthService } from '@app/shared/services';
import { LeagueYearLookupView } from '@app/shared/models';
import { AccessTokenModel } from '@app/shared/models/auth.models';

import { LookupCodes, Messages, AppPermissionCodes } from '@app/constants';

@Component({
	selector: 'app-menu-modal',
	standalone: true,
	templateUrl: './menu-modal.component.html',
	styleUrl: './menu-modal.component.scss',
	imports: [
		CommonModule, RouterLink, RouterLinkActive, FormsModule,
		DetailsIconComponent
	],
})
export class MenuModalComponent {
	// internal state
	protected selectedLeagueYear = model<LeagueYearLookupView | null>(null);
	protected userProfile?: AccessTokenModel;
	protected userInitials?: string;

	// services
	private router: Router = inject(Router);
	private toastrService: ToastrService = inject(ToastrService);
	protected activeModal = inject(NgbActiveModal);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	private lookupService: LookupService = inject(LookupService);
	protected authService: AuthService = inject(AuthService);

	// lookups
	protected leagueYearsLookup$!: Observable<LeagueYearLookupView[]>;
	protected leagueYearsLookupLoading = true;

	// constants
	protected AppPermissionCodes = AppPermissionCodes;

	constructor() {
		this.initializeLookups();

		this.userProfile = this.authService.getUserProfile();
		this.userInitials = (this.userProfile?.given_name?.charAt(0) ?? '') + (this.userProfile?.family_name?.charAt(0) ?? '');

		// effects
		effect(() => {
			const leagueYear = this.selectedLeagueYear();
			if (leagueYear?.id && leagueYear?.name) {
				this.globalFiltersService.setLeagueYear(leagueYear.id, leagueYear.name);
			}
		});
	}

	private initializeLookups() {
		this.leagueYearsLookup$ = this.lookupService.getLookup<LeagueYearLookupView>(LookupCodes.LeagueYears)
			.pipe(
				tap(leagueYears => {
					setTimeout(() => this.leagueYearsLookupLoading = false, 0);
					this.selectedLeagueYear.set(leagueYears.find(ly => ly.current) ?? null);
				}),
				catchError(err => {
					setTimeout(() => this.leagueYearsLookupLoading = false, 0);
					return this.showLookupErrorToast('League Years', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	onSignOut() {
		try {
			this.authService.logout();
			this.router.navigate(['/login']);
		} catch (error) {
			console.error(error);
			this.toastrService.error('Unexpected sign out error. Please try again.');
		}
	}
}
