import { Component, inject, effect, model } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterLink, Router } from '@angular/router';

import { lastValueFrom } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightToArc, faSync } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';

import { AppInsightsService } from '@app/core/services/appinsights.service';
import { AuthService, LookupService } from '@app/shared/services';
import { InfoIconComponent, DetailsIconComponent, SpinnerComponent } from '@app/shared/components';

enum LoginStates {
	SignIn,
	SendVerificationCode,
	VerifyCode,
	ChangePassword,
}

@Component({
	selector: 'app-login-page',
	templateUrl: './login.page.html',
	styleUrl: './login.page.scss',
	standalone: true,
	imports: [
		CommonModule, FormsModule, RouterLink,
		FontAwesomeModule, RecaptchaV3Module, RecaptchaModule,
		SpinnerComponent, DetailsIconComponent, InfoIconComponent
	],
})
export class LoginPage {
	// inputs
	code = model<string>();

	// internal state
	// TODO: REMOVE CHANGING BACKGROUND VARS
	backgroundImageIndex = 8;
	onNextBackground() {
		this.backgroundImageIndex = ((this.backgroundImageIndex + 1) % 7) || 1;
	}

	// sign-in
	username: string = '';
	password: string = '';
	// change
	passwordNew: string = '';
	passwordConfirm: string = '';

	loginState: LoginStates = LoginStates.SignIn;
	processing = false;

	// services
	private router: Router = inject(Router);
	private appInsights: AppInsightsService = inject(AppInsightsService);
	private toastrService: ToastrService = inject(ToastrService);
	private modalService: NgbModal = inject(NgbModal);
	private authService: AuthService = inject(AuthService);
	private lookupService: LookupService = inject(LookupService);
	private recaptchaV3Service: ReCaptchaV3Service = inject(ReCaptchaV3Service);

	// icons
	signinIcon = faArrowRightToArc;
	syncIcon = faSync;

	// constants
	LoginStates = LoginStates;

	constructor() {
		effect(() => {
			this.loginState = this.code() ? LoginStates.VerifyCode : LoginStates.SignIn;
			if (this.loginState === LoginStates.VerifyCode) {
				this.onVerifyCode();
			}
		});

		// close any open modals
		this.modalService.dismissAll();
		// clear all cached lookups when showing login page (assume lost auth)
		this.lookupService.clearAllLookups();
		// // clear any global filters when showing login page (assume lost auth)
		// console.log(`>>> LOGIN >>> CLEARING GLOBAL FILTERS`);
		// this.globalFiltersService.setLeagueYear(null, null);
	}

	onSubmit() {
		switch (this.loginState) {
			case LoginStates.SignIn:
				this.appInsights.logEvent('Login', { username: this.username });
				this.onSignIn();
				break;
			case LoginStates.SendVerificationCode:
				this.appInsights.logEvent('SendVerificationCode', { username: this.username });
				this.onSendVerificationCode();
				break;
			case LoginStates.VerifyCode:
				this.appInsights.logEvent('VerifyCode', { username: this.username });
				this.onVerifyCode();
				break;
			case LoginStates.ChangePassword:
				this.appInsights.logEvent('ChangePassword', { username: this.username });
				this.onSetNewPassword();
				break;
		}
	}

	private async onSignIn() {
		console.log('Signing in...');

		try {
			this.processing = true;
			const response = await this.authService.login(this.username, this.password);

			if (!response.success) {
				this.toastrService.error(response.message);
			} else {
				if (this.authService.redirectUrl) {
					// redirect to the page the user was trying to access
					const redirectUrl = this.authService.redirectUrl;
					this.authService.redirectUrl = null;

					let redirectPath = redirectUrl;
					if (Array.isArray(redirectUrl)) {
						redirectPath = redirectUrl.map((segment) => segment.path).join('/');
					}
					console.log('>>> REDIRECTING TO:', redirectPath);
					this.router.navigate(['/' + redirectPath]);
				} else {
					// redirect to the home page
					this.router.navigate(['/home']);
				}
			}
		} catch (error) {
			this.toastrService.error('Unexpected sign in error. Please try again.');
		} finally {
			this.processing = false;
		}
	}

	private async onSendVerificationCode() {
		if (this.processing) {
			return;
		}

		try {
			this.processing = true;

			const resetToken = await lastValueFrom(this.recaptchaV3Service.execute('resetPassword'));
			await this.authService.resetUserPassword(this.username, resetToken);
			this.loginState = LoginStates.VerifyCode;
		} catch (err: any) {
			const errorMessage = err?.error?.Message || 'Failed to request password reset for your email address. Please try again.';
			this.toastrService.error(errorMessage, 'Password Reset Error');
		} finally {
			this.processing = false;
		}
	}

	async onVerifyCode() {
		if (!this.username || !this.code() || this.processing) {
			return;
		}

		try {
			this.processing = true;
			await this.authService.validateResetPasswordToken(this.code()!);

			this.loginState = LoginStates.ChangePassword;
		} catch (err: any) {
			const errorMessage = err?.error?.Message || 'Failed to verify password reset code. Please try again.';
			this.toastrService.error(errorMessage, 'Password Reset Error');
		} finally {
			this.processing = false;
		}
	}

	async onSetNewPassword() {
		if (!this.code() || this.processing || !this.isValidPassword()) {
			return;
		}

		try {
			this.processing = true;
			await this.authService.changeUserPassword(this.username, this.code()!, this.passwordNew);

			this.loginState = LoginStates.SignIn;
		} catch (err: any) {
			const errorMessage = err?.error?.Message || 'Failed to set new password. Please try again.';
			this.toastrService.error(errorMessage, 'Password Reset Error');
		} finally {
			this.processing = false;
		}
	}

	setLoginState(loginState: LoginStates = LoginStates.SignIn): LoginStates {
		return this.loginState = loginState
	}

	isValidPassword(): boolean {
		return this.passwordNew !== '' && this.passwordNew === this.passwordConfirm;
	}
}
