<div class="container pt-3 pt-sm-4">
	<!-- alerts -->
	<div class="row">
		<div class="col">
			<app-announcements />
		</div>
	</div>

	<!-- leagues -->
	<app-leagues />

	<!-- forum -->
	<app-forum />
</div>
