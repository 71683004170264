<div class="container pt-3 pt-sm-4">
	<!-- title -->
	<!-- DESKTOP TITLE -->
	<div class="d-none d-sm-flex align-items-center justify-content-between mb-4">
		<div class="d-flex align-items-center justify-content-between gap-2">
			<h1 class="mb-0 text-nowrap" for="teamSelect">{{ leagueName }}</h1>
			<span>—</span>
			<div class="dropdown" routerLinkActive="active" ngbDropdown>
				<button class="btn btn-lg btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
					aria-expanded="false" ngbDropdownToggle>
					@if (!teamsLoading) {
					{{ selectedTeam?.teamName || 'All Teams' }}
					} @else {
					<app-spinner />
					}
				</button>
				<ul class="dropdown-menu" ngbDropdownMenu>
					<li ngbDropdownItem (click)="onTeamChange(undefined)">All Teams</li>
					<li>
						<hr class="dropdown-divider">
					</li>
					@for (team of teams; track team.teamId) {
					<li ngbDropdownItem (click)="onTeamChange(team)">{{ team.teamName }}</li>
					}
				</ul>
			</div>
		</div>
		<button class="btn btn-lg me-n3" (click)="onCancel()"><app-close-icon /></button>
	</div>
	<!-- MOBILE TITLE -->
	<div class="d-block d-sm-none">
		<div class="d-flex align-items-center justify-content-between">
			<h1 class="mb-0">{{ leagueName }}</h1>
			<button class="btn btn-lg me-n3" (click)="onCancel()"><app-close-icon /></button>
		</div>
		<div class="dropdown mb-4" routerLinkActive="active" ngbDropdown>
			<button class="btn btn-lg btn-outline-dark dropdown-toggle w-100" type="button" data-bs-toggle="dropdown"
				aria-expanded="false" ngbDropdownToggle>
				@if (!teamsLoading) {
				{{ selectedTeam?.teamName || 'All Teams' }}
				} @else {
				<app-spinner />
				}
			</button>
			<ul class="dropdown-menu" ngbDropdownMenu>
				<li ngbDropdownItem (click)="onTeamChange(undefined)">All Teams</li>
				<li>
					<hr class="dropdown-divider">
				</li>
				@for (team of teams; track team.teamId) {
				<li ngbDropdownItem (click)="onTeamChange(team)">{{ team.teamName }}</li>
				}
			</ul>
		</div>
	</div>

	<!-- <div>{{ leagueID || 'null' }} / {{ teamID || 'null' }}</div> -->

	@if (docsLoading) {
	<!-- DESKTOP VIEW -->
	<div class="d-none d-xl-block">
		<app-table-placeholder class="mt-4" [columnsCount]="5" [rowsCount]="3" />
		<app-table-placeholder class="mt-4" [columnsCount]="4" [rowsCount]="4" />
		<app-table-placeholder class="mt-4" [columnsCount]="5" [rowsCount]="16" />
	</div>
	<!-- MOBILE VIEW -->
	<div class="d-block d-xl-none">
		<app-card-placeholder class="mt-4" [bodyRowItemCounts]="[[4]]" [twoLineItems]="true" />
		<app-card-placeholder class="mt-4" [bodyRowItemCounts]="[[4]]" [twoLineItems]="true" />
		<app-card-placeholder class="mt-4" [bodyRowItemCounts]="[[4]]" [twoLineItems]="true" />
	</div>
	}
	@else if (docsError) {
	<app-error class="mt-4" message="Error loading document intake" (retry)="onLoadDocumentIntake()" />
	} @else if (!docs) {
	<div class="card mt-4">
		<div class="card-body"><app-empty
				message="{{ selectedTeam ? ('No documents for ' + selectedTeam.teamName + ' team') : ('No documents for ' + leagueName + ' league') }}" />
		</div>
	</div>
	} @else {
	<!-- DESKTOP VIEW -->
	<div class="d-none d-xl-block">
		<h2>Team Documents</h2>
		@if (docs.tableDocs.teamDocuments.length > 0) {
		<div class="table-responsive">
			<table class="table table-hover table-bordered align-middle mb-0">
				<thead class="table-secondary">
					<tr>
						<th style="min-width: 175px;" scope="col">Team</th>
						@for (docType of docs.tableDocs.teamDocumentTypes; track docType.documentTypeID) {
						<th [style.width]="100 / docs.tableDocs.teamDocumentTypes.length + '%'" scope="col">
							<div class="hstack gap-2">
								{{ docType.documentTypeName }}
								@if (docType.required) { <app-required-icon /> }
							</div>
						</th>
						}
					</tr>
				</thead>
				<tbody>
					@for (team of docs.tableDocs.teamDocuments; track team.entityID) {
					<tr>
						<td>
							<h5 class="mb-0 text-nowrap">{{ team.entityName }}</h5>
						</td>
						@for (docType of docs.tableDocs.teamDocumentTypes; track docType.documentTypeID) {
						<td scope="col">
							<app-document-card [document]="team.documents[docType.documentTypeID]" [cardless]="true"
								titleType="status" [showInlineStatusNotes]="true" [showUpload]="true"
								(uploaded)="onTeamDocumentUploaded(team.documents[docType.documentTypeID], $event)"
								(approved)="onTeamDocumentUpdated($event)" (rejected)="onTeamDocumentUpdated($event)" />
						</td>
						}
					</tr>
					}
				</tbody>
			</table>
		</div>
		} @else {
		<div class="card">
			<div class="card-body"><app-empty
					message="There are no team documents {{ selectedTeam ? 'for ' + selectedTeam.teamName + ' team' : '' }}" />
			</div>
		</div>
		}

		<h2 class="mt-5">Coach Documents</h2>
		@if (docs.tableDocs.coachDocuments.length > 0) {
		<div class="table-responsive">
			<table class="table table-hover table-bordered align-middle mb-0">
				<thead class="table-secondary">
					<tr>
						<th style="min-width: 250px;" scope="col">Coach</th>
						@for (docType of docs.tableDocs.coachDocumentTypes; track docType.documentTypeID) {
						<th [style.width]="100 / docs.tableDocs.coachDocumentTypes.length + '%'" scope="col">
							<div class="hstack gap-2">
								{{ docType.documentTypeName }}
								@if (docType.required) { <app-required-icon /> }
							</div>
						</th>
						}
					</tr>
				</thead>
				<tbody>
					@for (coach of docs.tableDocs.coachDocuments; track coach.entityID; let index = $index) {
					<!-- team name grouping row -->
					@if (!selectedTeam && (index === 0 || coach.teamID !== docs.tableDocs.coachDocuments[index -
					1].teamID)) {
					<tr class="table-secondary">
						<td [colSpan]="1 + docs.tableDocs.coachDocumentTypes.length">
							<h4 class="mb-0">{{ coach.teamName }}</h4>
						</td>
					</tr>
					}
					<!-- team coaches -->
					<tr>
						<td>
							<h5 class="mb-0 hstack gap-2">
								<span class="text-nowrap">{{ coach.entityName }}</span>
								@if (coach.isManager) { <span class="badge text-bg-light">Manager</span> }
							</h5>
						</td>
						@for (docType of docs.tableDocs.coachDocumentTypes; track docType.documentTypeID) {
						<td scope="col">
							<app-document-card [document]="coach.documents[docType.documentTypeID]" [cardless]="true"
								titleType="status" [showInlineStatusNotes]="true" [showUpload]="true"
								(uploaded)="onTeamDocumentUploaded(coach.documents[docType.documentTypeID], $event)"
								(approved)="onTeamDocumentUpdated($event)" (rejected)="onTeamDocumentUpdated($event)" />
						</td>
						}
					</tr>
					}
				</tbody>
			</table>
		</div>
		} @else {
		<div class="card">
			<div class="card-body"><app-empty
					message="There are no coach documents {{ selectedTeam ? 'for ' + selectedTeam.teamName + ' team' : '' }}" />
			</div>
		</div>
		}

		<h2 class="mt-5">Player Documents</h2>
		@if (docs.tableDocs.playerDocuments.length > 0) {
		<div class="table-responsive">
			<table class="table table-hover table-bordered align-middle mb-0">
				<thead class="table-secondary">
					<tr>
						<th style="min-width: 275px;" scope="col">Player</th>
						@for (docType of docs.tableDocs.playerDocumentTypes; track docType.documentTypeID) {
						<th [style.width]="100 / docs.tableDocs.playerDocumentTypes.length + '%'" scope="col">
							<div class="hstack gap-2">
								{{ docType.documentTypeName }}
								@if (docType.required) { <app-required-icon /> }
							</div>
						</th>
						}
					</tr>
				</thead>
				<tbody>
					@for (player of docs.tableDocs.playerDocuments; track player.entityID; let index = $index) {
					<!-- team name grouping row -->
					@if (!selectedTeam && (index === 0 || player.teamID !== docs.tableDocs.playerDocuments[index -
					1].teamID)) {
					<tr class="table-secondary">
						<td [colSpan]="1 + docs.tableDocs.playerDocuments.length">
							<h4 class="mb-0">{{ player.teamName }}</h4>
						</td>
					</tr>
					}
					<!-- team players -->
					<tr>
						<td>
							<div class="hstack gap-2">
								@defer (on viewport) {
								<app-document-thumbnail class="img-thumbnail rounded-circle"
									[documentFileID]="player.headshotFileID" [emptyChars]="player.initials" />
								} @placeholder {
								<app-spinner />
								}
								<div>
									<h5 class="text-nowrap mb-1">{{ player.entityName }}</h5>
									<div class="hstack gap-2">
										<span class="badge text-bg-light">{{ player.age }}yo</span>
										<span class="fw-normal small">{{ player.dateOfBirth |
											date:'M/dd/yyyy' }}</span>
									</div>
								</div>
							</div>
						</td>
						@for (docType of docs.tableDocs.playerDocumentTypes; track docType.documentTypeID) {
						<td scope="col">
							<app-document-card [document]="player.documents[docType.documentTypeID]" [cardless]="true"
								titleType="status" [showInlineStatusNotes]="true" [showUpload]="true"
								(uploaded)="onTeamDocumentUploaded(player.documents[docType.documentTypeID], $event)"
								(approved)="onTeamDocumentUpdated($event)" (rejected)="onTeamDocumentUpdated($event)" />
						</td>
						}
					</tr>
					}
				</tbody>
			</table>
		</div>
		} @else {
		<div class="card">
			<div class="card-body"><app-empty
					message="There are no player documents {{ selectedTeam ? 'for ' + selectedTeam.teamName + ' team' : '' }}" />
			</div>
		</div>
		}

		<div class="my-5"></div>
	</div>

	<!-- MOBILE VIEW -->
	<div class="d-block d-xl-none">
		<!-- team documents -->
		@for (team of docs.listDocs; track team.teamID) {
		<!-- <app-team-icon class="me-2" /> -->
		<h2 class="mb-3"> {{ team.teamName }} - Team Documents</h2>
		<div class="card mb-4">
			<div class="card-body">
				<div class="row gx-2 gy-2">
					@for (doc of team.documents; track doc.documentId) {
					<div class="col">
						<app-document-card [document]="doc" [showInlineStatusNotes]="true" [showUpload]="true"
							(uploaded)="onTeamDocumentUploaded(doc, $event)" (approved)="onTeamDocumentUpdated($event)"
							(rejected)="onTeamDocumentUpdated($event)" />
					</div>
					}
				</div>
			</div>
		</div>

		<!-- coach documents -->
		@if (team.coachDocuments.length > 0) {
		<div class="row g-3 mb-3">
			<div class="col">
				<!-- <app-coach-icon class="me-2" /> -->
				<h3 class="mb-0">{{ team.teamName }} - Coach Documents</h3>
			</div>
			<div class="col-auto">
				<label class="form-check-label me-3">Group by</label>
				<div class="form-check form-check-inline">
					<input id="coachGroupByCoach{{ team.teamID }}" name="coachGroupBy{{ team.teamID }}"
						class="form-check-input" type="radio" value="coach" [(ngModel)]="team.coachGroupBy">
					<label class="form-check-label text-dark" [class.fw-medium]="team.coachGroupBy === 'coach'"
						for="coachGroupByCoach{{ team.teamID }}">Coach</label>
				</div>
				<div class="form-check form-check-inline">
					<input id="coachGroupByDoc{{ team.teamID }}" name="coachGroupBy{{ team.teamID }}"
						class="form-check-input" type="radio" value="doc" [(ngModel)]="team.coachGroupBy">
					<label class="form-check-label text-dark" [class.fw-medium]="team.coachGroupBy === 'doc'"
						for="coachGroupByDoc{{ team.teamID }}">Document
						Type</label>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			@if (team.coachGroupBy === 'coach') {
			@for (coach of team.coachDocuments; track coach.coachID) {
			<div class="card-header bg-dark-subtle fw-bold py-3">
				<h5 class="mb-0">{{ coach.coachName }} @if (coach.isCoachManager) { <span
						class="badge text-bg-light ms-2">Manager</span> }</h5>
			</div>
			<div class="card-body">
				<div class="row gx-2 gy-2">
					@for (doc of coach.documents; track doc.documentId) {
					<div class="col">
						<app-document-card [document]="doc" [showInlineStatusNotes]="true" [showUpload]="true"
							(uploaded)="onCoachDocumentUploaded(doc, $event)"
							(approved)="onCoachDocumentUpdated($event)" (rejected)="onCoachDocumentUpdated($event)" />
					</div>
					}
				</div>
			</div>
			}
			} @else {
			@for (doc of team.documentCoaches; track doc.documentID) {
			<div class="card-header bg-dark-subtle fw-bold py-3">
				<h5 class="mb-0">{{ doc.documentName }} @if (doc.required) { <app-required-icon class="ms-2" /> }</h5>
			</div>
			<div class="card-body">
				<div class="row gx-2 gy-2">
					@for (coach of doc.coaches; track coach.coachId) {
					<div class="col">
						<app-document-card [document]="coach" titleType="entity" [showInlineStatusNotes]="true"
							[showUpload]="true" (uploaded)="onCoachDocumentUploaded(coach, $event)"
							(approved)="onCoachDocumentUpdated($event)" (rejected)="onCoachDocumentUpdated($event)" />
					</div>
					}
				</div>
			</div>
			}
			}
		</div>
		}

		<!-- player documents -->
		@if (team.playerDocuments.length > 0) {
		<div class="row g-3 mb-3">
			<div class="col">
				<!-- <app-player-icon class="me-2" /> -->
				<h3 class="mb-0">{{ team.teamName }} - Player Documents</h3>
			</div>
			<div class="col-auto">
				<label class="form-check-label me-3">Group by</label>
				<div class="form-check form-check-inline">
					<input id="playerGroupByPlayer{{ team.teamID }}" name="playerGroupBy{{ team.teamID }}"
						class="form-check-input" type="radio" value="player" [(ngModel)]="team.playerGroupBy">
					<label class="form-check-label text-dark" [class.fw-medium]="team.playerGroupBy === 'player'"
						for="playerGroupByPlayer{{ team.teamID }}">Player</label>
				</div>
				<div class="form-check form-check-inline">
					<input id="playerGroupByDoc{{ team.teamID }}" name="playerGroupBy{{ team.teamID }}"
						class="form-check-input" type="radio" value="doc" [(ngModel)]="team.playerGroupBy">
					<label class="form-check-label text-dark" [class.fw-medium]="team.playerGroupBy === 'doc'"
						for="playerGroupByDoc{{ team.teamID }}">Document
						Type</label>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			@if (team.playerGroupBy === 'player') {
			@for (player of team.playerDocuments; track player.playerTeamID) {
			<div class="card-header bg-dark-subtle fw-bold py-2">
				<div class="hstack gap-2">
					@defer (on viewport) {
					<app-document-thumbnail class="img-thumbnail rounded-circle" size="sm"
						[documentFileID]="player.playerHeadshotFileID" [emptyChars]="player.playerInitials" />
					} @placeholder {
					<app-spinner />
					}
					<h5 class="mb-0">
						{{ player.playerName }}
						<span class="fw-normal ms-1 small">({{ player.playerDateOfBirth |
							date:'M/dd/yyyy' }})</span>
						<span class="badge text-bg-light ms-2">{{ player.playerAge }}yo</span>
					</h5>
				</div>
			</div>
			<div class="card-body">
				<div class="row gx-2 gy-2">
					@for (doc of player.documents; track doc.documentId) {
					<div class="col">
						<app-document-card [document]="doc" [showInlineStatusNotes]="true" [showUpload]="true"
							(uploaded)="onPlayerDocumentUploaded(doc, $event)"
							(approved)="onPlayerDocumentUpdated($event)" (rejected)="onPlayerDocumentUpdated($event)" />
					</div>
					}
				</div>
			</div>
			}
			} @else {
			@for (doc of team.documentPlayers; track doc.documentID) {
			<div class="card-header bg-dark-subtle fw-bold py-3">
				<h5 class="mb-0">{{ doc.documentName }} @if (doc.required) { <app-required-icon class="ms-2" /> }</h5>
			</div>
			<div class="card-body">
				<div class="row gx-2 gy-2">
					@for (player of doc.players; track player.playerTeamId) {
					<div class="col-sm-6">
						<app-document-card [document]="player" titleType="entity" [showInlineStatusNotes]="true"
							[showUpload]="true" (uploaded)="onPlayerDocumentUploaded(player, $event)"
							(approved)="onPlayerDocumentUpdated($event)" (rejected)="onPlayerDocumentUpdated($event)" />
					</div>
					}
				</div>
			</div>
			}
			}
		</div>
		}
		}
	</div>
	}

	<!-- <br /><br /><br /><br /><br />
	<pre>
		{{ docs | json }}
	</pre> -->
</div>

<!-- <div class="modal-header">
	<div class="container">
		<div class="d-flex align-items-center justify-content-between">
			<h4 class="modal-title">Document Intake</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="onDismiss()"></button>
		</div>
	</div>
</div>
<div class="modal-body">
	<div class="container pt-4">
		<h1>DOCUMENT INTAKE</h1>

		<div>{{ leagueID || 'null' }} / {{ teamID || 'null' }} / {{ teamPlayerID || 'null' }}</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="onClose()">Close</button>
</div> -->
