import { Component, ViewChildren, QueryList, HostListener, AfterViewInit, inject, VERSION } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppInsightsService } from '@app/core/services/appinsights.service';
import { AppLoadingService } from '@app/core/services/app-loader.service';
import { ModalRoutingService } from '@app/core/services/modal-routing.service';
import { SpinnerComponent } from '@app/shared/components';

import * as version from '../version.json';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		CommonModule, RouterOutlet,
		NgxScrollTopModule,
		SpinnerComponent
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {
	private appInsights: AppInsightsService = inject(AppInsightsService);
	protected appLoadingService: AppLoadingService = inject(AppLoadingService);
	private modalRoutingService: ModalRoutingService = inject(ModalRoutingService);

	angularVersion = VERSION.full;
	appVersion = version;

	// @ViewChildren(NgbTooltip) tooltips?: QueryList<NgbTooltip>;

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent) {
		this.closeAllTooltips();
	}

	ngAfterViewInit() {
		// this.tooltips?.changes.subscribe((tooltips: QueryList<NgbTooltip>) => {
		// 	console.log('Tooltips updated', tooltips);
		// });
	}

	private closeAllTooltips() {
		// console.log(`Closing all tooltips: ${this.tooltips?.length || 'NONE'}`);
		// this.tooltips?.forEach((tooltip) => tooltip.close());

		// TODO: REVIEW THIS TOOLTIP CLOSING CODE - TRY TO CLOSE THROUGH ViewChildren
		const visibleTooltips = document.querySelectorAll('.tooltip.show');
		visibleTooltips.forEach(tooltip => {
			// Bootstrap tooltips can be closed by hiding the tooltip element
			tooltip.classList.remove('show'); // This hides the tooltip
		});
	}
}
