import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideRouter, withComponentInputBinding, withViewTransitions } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideToastr } from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { ROUTES } from './app.routes';
import { AuthHttpInterceptor } from './core/interceptors/http-auth.interceptor';
import { TimeoutHttpInterceptor, DEFAULT_TIMEOUT } from './core/interceptors/http-timeout.interceptor';

import { Environment } from '@app/environment';

export const appConfig: ApplicationConfig = {
	providers: [
		{
			provide: ErrorHandler,
			useClass: ApplicationinsightsAngularpluginErrorService
		},
		provideRouter(ROUTES, withViewTransitions(), withComponentInputBinding()),
		provideAnimationsAsync(),
		provideEnvironmentNgxMask(),
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: Environment.RECAPTCHA_V3_SITE_KEY,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TimeoutHttpInterceptor,
			multi: true
		},
		{ provide: DEFAULT_TIMEOUT, useValue: 30 * 1000 }, // 30 second default HTTP timeout
		provideToastr(),
		// TODO: CONSIDER CONFIGURING QUILL EDITOR GLOBALLY
		// provideQuillConfig({
		// 	modules: {
		// 		syntax: true,
		// 		toolbar: [...]
		// 	}
		// })
	]
};
