import { CommonModule, Location } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Observable, Subscription, tap, catchError, throwError, of } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule, ContentChange } from 'ngx-quill';

import {
	SpinnerComponent,
	CardPlaceholderComponent,
	ErrorComponent,
	CloseIconComponent
} from '@app/shared/components';
import { LookupService, ForumService } from '@app/shared/services';
import { LookupView, ForumPostEditModel } from '@app/shared/models';
import { LookupCodes, Messages } from '@app/constants';

@Component({
	selector: 'app-post-edit-page',
	templateUrl: './post-edit.page.html',
	styleUrl: './post-edit.page.scss',
	standalone: true,
	imports: [
		CommonModule, FormsModule,
		NgbTooltipModule, NgSelectModule, QuillModule,
		SpinnerComponent, CardPlaceholderComponent, ErrorComponent, CloseIconComponent
	]
})
export class PostEditPage implements OnInit {
	@Input() postID?: number;

	// services
	private location: Location = inject(Location);
	private toastrService: ToastrService = inject(ToastrService);
	private lookupService: LookupService = inject(LookupService);
	private forumService: ForumService = inject(ForumService);

	// internal state
	modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],        // toggled buttons
			['blockquote'], //'code-block'

			[{ 'header': 1 }, { 'header': 2 }],               // custom button values
			[{ 'list': 'ordered' }, { 'list': 'bullet' }],
			// [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
			[{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
			// [{ 'direction': 'rtl' }],                         // text direction

			[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

			[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
			[{ 'font': [] }],
			[{ 'align': [] }],

			['clean'],                                         // remove formatting button

			['link', 'image', 'video']                         // link and image, video
		]
	};

	post: ForumPostEditModel | null = null;
	visibleInRegions: LookupView[] = [];
	get canSavePost() {
		return this.post && this.post.title && this.post.content;
	}
	postLoading = false;
	postError = false;
	postSaving = false;

	// lookups
	regionsLookup$!: Observable<LookupView[]>;
	regionsLookupLoading = true;

	constructor() {
		this.initializeLookups();
	}

	ngOnInit() {
		if (this.postID) {
			this.onLoadPost();
		} else {
			this.initializeNewPost();
		}

		window.scrollTo(0, 0);
	}

	private initializeNewPost() {
		this.post = {
			title: '',
			content: '',
			isPinned: false,
			allowComments: true,
			isGlobal: true,
			visibleInRegionIDs: null
		};
	}

	private initializeLookups() {
		this.regionsLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Regions)
			.pipe(
				tap(() => setTimeout(() => this.regionsLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.regionsLookupLoading = false, 0);
					return this.showLookupErrorToast('Regions', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	async onLoadPost() {
		if (this.postLoading) return;

		this.postLoading = true;
		this.postError = false;

		try {
			const post = await this.forumService.getPostDetails(this.postID!);
			this.post = {
				title: post.title,
				content: post.content,
				isPinned: post.isPinned,
				allowComments: post.allowComments,
				isGlobal: post.isGlobal,
				visibleInRegionIDs: post.visibleInRegionIDs
			};

			const regionsSubscription = this.regionsLookup$.subscribe(regions => {
				this.visibleInRegions = regions.filter(r => post.visibleInRegionIDs?.includes(r.id));
				regionsSubscription?.unsubscribe();
			});
		} catch (error) {
			this.postError = true;
		} finally {
			this.postLoading = false;
		}
	}

	onContentChange(e: ContentChange) {
		console.log(e.text);
	}

	onGlobalChange() {
		if (!this.post) return;

		if (this.post.isGlobal) {
			this.post.visibleInRegionIDs = null;
		}
	}

	onVisibleInRegionsChange() {
		if (!this.post) return;

		this.post.visibleInRegionIDs = this.visibleInRegions?.map(r => r.id);
	}

	onCancel() {
		this.location.back();
	}

	async onSave() {
		if (!this.post || this.postSaving) return;

		this.postSaving = true;

		try {
			const savedPost = !this.postID
				? await this.forumService.createPost(this.post)
				: await this.forumService.updatePost(this.postID!, this.post);

			this.toastrService.success(`Post saved successfully`);
			this.location.back(); // TODO: REVIEW THIS - MAYBE TAKE RETURN ROUTE AS PARAM
		} catch (error) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Save Post`);
		} finally {
			this.postSaving = false;
		}
	}

	// protected compareRegions(a: LookupView, b: LookupView) {
	// 	return a.id === b.id;
	// }
}
