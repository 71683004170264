import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
	catchError,
	debounceTime,
	distinctUntilChanged, EMPTY,
	Observable,
	of,
	Subject,
	switchMap,
	tap,
	throwError,
} from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { GlobalSearchModel, LeagueYearLookupView, PagedModel } from '@app/shared/models';
import { GlobalSearchService, LookupService } from '@app/shared/services';
import {
	CoachIconComponent,
	DocumentIconComponent,
	EmptyComponent,
	ErrorComponent,
	LeagueIconComponent,
	PlayerIconComponent,
	StatusIconComponent,
	StatusTypes,
	TablePlaceholderComponent,
	TeamIconComponent,
} from '@app/shared';
import { EntityTypeNames, LookupCodes, Messages } from '@app/constants';
import { RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-search-page',
	templateUrl: './search.page.html',
	styleUrl: './search.page.scss',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		FormsModule,
		TablePlaceholderComponent,
		ErrorComponent,
		EmptyComponent,
		DatePipe,
		InfiniteScrollModule,
		NgbTooltip,
		LeagueIconComponent,
		TeamIconComponent,
		RouterLink,
		CoachIconComponent,
		PlayerIconComponent,
		StatusIconComponent,
		AsyncPipe,
		DocumentIconComponent,
	],
})
export class SearchPage implements OnInit {
	// internal state
	searchResults: GlobalSearchModel[] | null = null;
	searchTextChange: Subject<string> = new Subject<string>();
	searchText: string = '';
	pageIndex: number = 1;
	searchLoading: boolean = false;
	searchError: boolean = false;
	resultTotal: number | null = null;
	// TODO: MOVE FILTER VARIABLES INTO FILTERS STRUCTURE
	leagueYearId: number | null = null;
	includeLeagues: boolean = false;
	includeTeams: boolean = false;
	includePlayers: boolean = false;
	includeCoaches: boolean = false;
	includeDocuments: boolean = false;

	get includeEverything() {
		// if all true or all false
		return (
			(this.includeLeagues && this.includeTeams && this.includePlayers && this.includeCoaches && this.includeDocuments) ||
			(!this.includeLeagues && !this.includeTeams && !this.includePlayers && !this.includeCoaches && !this.includeDocuments)
		);
	}

	// services
	private searchService: GlobalSearchService = inject(GlobalSearchService);
	private destroyRef: DestroyRef = inject(DestroyRef);
	private lookupService: LookupService = inject(LookupService);
	private toastrService: ToastrService = inject(ToastrService);

	// lookups
	protected leagueYearsLookup$!: Observable<LeagueYearLookupView[]>;
	protected leagueYearsLookupLoading = true;

	// constants
	protected readonly EntityTypeNames = EntityTypeNames;
	protected readonly StatusTypes = StatusTypes;

	ngOnInit() {
		this.initializeLookups();
		this.connectLeagueNameSearch();
	}

	private get isSearchEmpty() {
		return !this.searchText || this.searchText.trim() === '';
	}

	async onLoadSearchResults(reset: boolean) {
		// todo add to the request this.isCoach, this.isLeague, this.isTeam, this.isPlayer, this.isDocument

		if (this.isSearchEmpty || this.searchLoading) {
			return;
		}

		if (reset) {
			this.resetSearchResults();
		}

		try {
			this.searchLoading = true;
			this.searchError = false;

			const includeAll = this.includeEverything;
			const nextSearchResults: PagedModel<GlobalSearchModel> = await this.searchService.getSearchResults(
				this.searchText,
				includeAll || this.includeLeagues,
				includeAll || this.includeTeams,
				includeAll || this.includePlayers,
				includeAll || this.includeCoaches,
				includeAll || this.includeDocuments,
				this.pageIndex,
				this.leagueYearId
			);

			this.processSearchResultsPageData(nextSearchResults);
		} catch (error) {
			this.searchError = true;
		} finally {
			this.searchLoading = false;
		}
	}

	private initializeLookups() {
		this.leagueYearsLookup$ = this.lookupService.getLookup<LeagueYearLookupView>(LookupCodes.LeagueYears).pipe(
			tap(() => {
				setTimeout(() => (this.leagueYearsLookupLoading = false), 0);
			}),
			catchError((err) => {
				setTimeout(() => (this.leagueYearsLookupLoading = false), 0);
				return this.showLookupErrorToast('League Years', err);
			})
		);
	}

	private connectLeagueNameSearch() {
		this.searchTextChange
			.pipe(
				// 500ms input delay
				debounceTime(500),
				// ignore if query hasn't changed
				distinctUntilChanged(),
				// switch to new search observable (and cancel previous)
				switchMap((query) => {
					// save search query
					this.searchText = query;
					// reset results and page
					this.resetSearchResults();

					if (this.isSearchEmpty) {
						return EMPTY;
					}

					// show loading indicator
					this.searchLoading = true;
					this.searchError = false;

					const includeAll = this.includeEverything;
					return this.searchService.getSearchResults$(
						this.searchText,
						includeAll || this.includeLeagues,
						includeAll || this.includeTeams,
						includeAll || this.includePlayers,
						includeAll || this.includeCoaches,
						includeAll || this.includeDocuments,
						this.pageIndex,
						this.leagueYearId
					).pipe(
						// handle fetch errors
						catchError((error) => {
							console.error('error searching', error);
							this.searchError = true;
							this.searchLoading = false;
							return of(null);
						}),
						takeUntilDestroyed(this.destroyRef)
					);
				})
			)
			.subscribe(
				response => {
					this.processSearchResultsPageData(response);
					this.searchLoading = false;
				});
	}

	private resetSearchResults() {
		this.searchResults = null;
		this.pageIndex = 1;
		this.resultTotal = null;
	}

	private processSearchResultsPageData(pageResult: PagedModel<GlobalSearchModel> | null) {
		if (!pageResult) {
			return;
		}

		if (!this.searchResults) {
			this.searchResults = [];
		}

		this.searchResults.push(
			...pageResult.pageData.filter(
				(s: GlobalSearchModel) =>
					this.searchResults?.findIndex(
						(ss: GlobalSearchModel) =>
							s.leagueId === ss.leagueId &&
							s.teamId === ss.teamId &&
							s.playerTeamId === ss.playerTeamId &&
							s.coachId === ss.coachId
					) === -1
			)
		);
		this.resultTotal = pageResult.totalRecords || this.searchResults.length;

		if (pageResult.pageData.length > 0) {
			this.pageIndex++;
		}
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}
