import { CommonModule, Location } from '@angular/common';
import { Component, Input, OnInit, OnDestroy, inject, numberAttribute } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Subscription, skip } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import {
	SpinnerComponent,
	TablePlaceholderComponent,
	CardPlaceholderComponent,
	EmptyComponent,
	ErrorComponent,
	TeamIconComponent,
	CoachIconComponent,
	PlayerIconComponent,
	CloseIconComponent,
	RequiredIconComponent,
	DocumentThumbnailComponent,
	DocumentCardComponent
} from '@app/shared/components';
import { GlobalFiltersService, TeamsService, DocumentsService, LookupService } from '@app/shared/services';
import {
	TeamListViewModel,
	DocumentIntakeTableViewModel,
	TeamDocumentIntakeTableViewModel,
	CoachDocumentIntakeTableViewModel,
	PlayerDocumentIntakeTableViewModel,
	TeamDocumentIntakeViewModel,
	EntityDocumentViewModel,
	DocumentTypeLookupView
} from '@app/shared/models';
import { DocumentStatusCodes, LookupCodes, Messages, DocumentSubjectCodes } from '@app/constants';

@Component({
	selector: 'app-document-intake-page',
	templateUrl: './document-intake.page.html',
	styleUrl: './document-intake.page.scss',
	standalone: true,
	imports: [
		CommonModule, FormsModule, NgbDropdownModule,
		SpinnerComponent, TablePlaceholderComponent, CardPlaceholderComponent, EmptyComponent, ErrorComponent,
		TeamIconComponent, CoachIconComponent, PlayerIconComponent, CloseIconComponent, RequiredIconComponent,
		DocumentThumbnailComponent, DocumentCardComponent
	]
})
export class DocumentIntakePage implements OnInit, OnDestroy {
	@Input({ required: true, transform: numberAttribute }) leagueID!: number;
	@Input() leagueName?: string;
	@Input({ transform: numberAttribute }) teamID?: number = undefined;

	// internal state
	selectedTeam?: TeamListViewModel = undefined;

	teams: TeamListViewModel[] | null = null;
	teamsLoading = false;
	teamsError = false;

	docs: {
		tableDocs: DocumentIntakeTableViewModel,
		listDocs: TeamDocumentIntakeViewModel[]
	} | null = null;
	// tableDocs: DocumentIntakeTableViewModel = {
	// 	teamDocs: [],
	// 	coachDocs: [],
	// 	playerDocs: []
	// };
	// docs: TeamDocumentIntakeViewModel[] = [];
	docsLoading = true;
	docsError = false;

	private leagueYearSubscription: Subscription | null = null;

	// services
	private location: Location = inject(Location);
	private toastrService: ToastrService = inject(ToastrService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	private lookupService: LookupService = inject(LookupService);
	private teamsService: TeamsService = inject(TeamsService);
	private documentsService: DocumentsService = inject(DocumentsService);

	// lookups
	// teamDocumentsLookup: DocumentTypeLookupView[] = [];
	// coachDocumentsLookup: DocumentTypeLookupView[] = [];
	// playerDocumentsLookup: DocumentTypeLookupView[] = [];
	// documentTypesLookupLoading = true;
	// documentTypesLookupSubscription: Subscription | null = null;

	// constructor() {
	// 	this.initializeLookups();
	// }

	// private initializeLookups() {
	// 	this.documentTypesLookupSubscription = this.lookupService.getLookup<DocumentTypeLookupView>(LookupCodes.DocumentTypes)
	// 		.pipe(
	// 			tap(() => setTimeout(() => this.documentTypesLookupLoading = false, 0)),
	// 			catchError(err => {
	// 				setTimeout(() => this.documentTypesLookupLoading = false, 0);
	// 				return this.showLookupErrorToast('Regions', err);
	// 			})
	// 		).subscribe(docTypes => {
	// 			this.teamDocumentsLookup = docTypes.filter(d => d.documentSubjectID === DocumentSubjectCodes.TeamDocument && d.uploadable);
	// 			this.coachDocumentsLookup = docTypes.filter(d => d.documentSubjectID === DocumentSubjectCodes.CoachDocument && d.uploadable);
	// 			this.playerDocumentsLookup = docTypes.filter(d => d.documentSubjectID === DocumentSubjectCodes.PlayerDocument && d.uploadable);
	// 		});
	// }

	// private showLookupErrorToast(lookupName: string, error: any) {
	// 	this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
	// 	return throwError(() => error);
	// }


	ngOnInit() {
		this.leagueYearSubscription = this.globalFiltersService.getLeagueYear$()
			.pipe(skip(1)) // skip the first value, which is the initial value
			.subscribe(() => {
				this.teamID = undefined;
				this.selectedTeam = undefined;
				this.onLoadTeams();
				this.onLoadDocumentIntake();
			});

		this.onLoadTeams();
		this.onLoadDocumentIntake();

		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		this.leagueYearSubscription?.unsubscribe();
		// this.documentTypesLookupSubscription?.unsubscribe();
	}

	onCancel() {
		this.location.back();
	}

	async onLoadTeams() {
		if (this.teamsLoading) return;

		this.teamsLoading = true;
		this.teamsError = false;

		try {
			this.teams = await this.teamsService.getTournamentTeams(this.leagueID);

			this.selectedTeam = this.teams.find(t => t.teamId === this.teamID) ?? undefined;
			console.log('selected team (teamID):', this.selectedTeam, this.teamID);
		} catch (error) {
			this.teamsError = true;
		} finally {
			this.teamsLoading = false;
		}
	}

	async onLoadDocumentIntake() {
		this.docsLoading = true;
		this.docsError = false;
		this.docs = null;

		try {
			this.docs = await this.documentsService.getDocumentIntake(this.leagueID!, this.teamID ?? null);
			console.log(this.docs);
		} catch (err) {
			this.docsError = true;
		} finally {
			this.docsLoading = false;
		}
	}

	onTeamChange(team: TeamListViewModel | undefined) {
		// console.log('selected team (teamID):', this.selectedTeam, this.teamID);
		this.selectedTeam = team;
		this.teamID = this.selectedTeam?.teamId;
		// console.log('selected team (teamID):', this.selectedTeam, this.teamID);
		this.onLoadDocumentIntake();
	}

	// TODO: REMOVE THIS AND USE onTeamDocumentUpdated ONCE UPLOAD API RETURNS FULL EntityDocumentViewModel
	onTeamDocumentUploaded(doc: EntityDocumentViewModel, fileID: number) {
		doc.documentSubmissionID = 0; // TODO: THIS NEEDS TO BE SET FOR APPROVE/REJECT TO WORK
		doc.uploadedFileId = fileID;
		// doc.uploadedFileType = ???
		doc.uploadedDateTime = new Date().toISOString();
		// doc.uploaderName = ???
		doc.statusId = DocumentStatusCodes.InProgress;
		doc.statusName = 'In Progress';
		doc.statusNotes = '';
	}

	onTeamDocumentUpdated(doc: EntityDocumentViewModel) {
		if (!this.docs) return;

		const teamIndex = this.docs.listDocs.findIndex(d => d.teamID === doc.teamId);
		if (teamIndex !== -1) {
			const index = this.docs.listDocs[teamIndex].documents.findIndex(dd => dd.documentId === doc.documentId);
			if (index !== -1) {
				this.docs.listDocs[teamIndex].documents[index] = doc;
			}
		}
	}

	// TODO: REMOVE THIS AND USE onCoachDocumentUpdated ONCE UPLOAD API RETURNS FULL EntityDocumentViewModel
	onCoachDocumentUploaded(doc: EntityDocumentViewModel, fileID: number) {
		doc.documentSubmissionID = 0; // TODO: THIS NEEDS TO BE SET FOR APPROVE/REJECT TO WORK
		doc.uploadedFileId = fileID;
		// doc.uploadedFileType = ???
		doc.uploadedDateTime = new Date().toISOString();
		// doc.uploaderName = ???
		doc.statusId = DocumentStatusCodes.InProgress;
		doc.statusName = 'In Progress';
		doc.statusNotes = '';
	}

	onCoachDocumentUpdated(doc: EntityDocumentViewModel) {
		if (!this.docs) return;

		const teamIndex = this.docs.listDocs.findIndex(d => d.teamID === doc.teamId);
		if (teamIndex !== -1) {
			const coachIndex = this.docs.listDocs[teamIndex].coachDocuments.findIndex(cd => cd.coachID === doc.coachId);
			if (coachIndex !== -1) {
				const docIndex = this.docs.listDocs[teamIndex].coachDocuments[coachIndex].documents.findIndex(dd => dd.documentId === doc.documentId);
				if (docIndex !== -1) {
					this.docs.listDocs[teamIndex].coachDocuments[coachIndex].documents[docIndex] = doc;
				}
			}
		}
	}

	// TODO: REMOVE THIS AND USE onPlayerDocumentUpdated ONCE UPLOAD API RETURNS FULL EntityDocumentViewModel
	onPlayerDocumentUploaded(doc: EntityDocumentViewModel, fileID: number) {
		doc.documentSubmissionID = 0; // TODO: THIS NEEDS TO BE SET FOR APPROVE/REJECT TO WORK
		doc.uploadedFileId = fileID;
		// doc.uploadedFileType = ???
		doc.uploadedDateTime = new Date().toISOString();
		// doc.uploaderName = ???
		doc.statusId = DocumentStatusCodes.InProgress;
		doc.statusName = 'In Progress';
		doc.statusNotes = '';
	}

	onPlayerDocumentUpdated(doc: EntityDocumentViewModel) {
		if (!this.docs) return;

		const teamIndex = this.docs.listDocs.findIndex(d => d.teamID === doc.teamId);
		if (teamIndex !== -1) {
			const playerIndex = this.docs.listDocs[teamIndex].playerDocuments.findIndex(pd => pd.playerTeamID === doc.playerTeamId);
			if (playerIndex !== -1) {
				const docIndex = this.docs.listDocs[teamIndex].playerDocuments[playerIndex].documents.findIndex(dd => dd.documentId === doc.documentId);
				if (docIndex !== -1) {
					this.docs.listDocs[teamIndex].playerDocuments[playerIndex].documents[docIndex] = doc;
				}
			}
		}
	}
}
