import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppLoadingService {
	isLoading$ = new BehaviorSubject<boolean>(false);

	setAppLoading(loading: boolean) {
		this.isLoading$.next(loading);
	}
}
