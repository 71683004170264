@if (appLoadingService.isLoading$ | async) {
<!-- app loader -->
<div class="d-flex justify-content-center align-items-center vh-100">
	<div class="text-center">
		<img src="assets/images/logo-vertical-light.svg?cache=1" alt="MLB RPM logo" height="250">
		<h1 id="loadingSpinner" class="mt-3 text-primary"><app-spinner /></h1>
	</div>
</div>
} @else {
<!-- main content pages -->
<router-outlet></router-outlet>

<!-- mode=smart -->
<ngx-scrolltop mode="classic" backgroundColor="#27251F" position="left"></ngx-scrolltop>
}

<!-- app version -->
<div class="position-fixed bottom-0 start-0 p-3 text-secondary">
	<small>v{{ appVersion.build }} (ng v{{ angularVersion }})</small>
</div>
