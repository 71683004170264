import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightFromArc, faSquareUser } from '@fortawesome/pro-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService, UsersService } from '@app/shared/services';
import {
	CardPlaceholderComponent,
	ErrorComponent,
	InlineEditComponent,
	InlineEditSaveEvent,
	ChangePasswordModal,
	ObjectViewerComponent,
	SpinnerComponent,
} from '@app/shared/components';
import { AccessTokenModel } from '@app/shared/models/auth.models';
import { UserModel } from '@app/shared/models/user.models';

@Component({
	selector: 'app-profile-page',
	templateUrl: './profile.page.html',
	styleUrl: './profile.page.scss',
	standalone: true,
	imports: [
		CommonModule,
		FontAwesomeModule,
		SpinnerComponent,
		ErrorComponent,
		CardPlaceholderComponent,
		ObjectViewerComponent,
		InlineEditComponent,
	],
})
export class ProfilePage implements OnInit {
	signoutIcon = faArrowRightFromArc;
	profileIcon = faSquareUser;

	userProfile$: Observable<AccessTokenModel | null>;
	user?: UserModel;
	userLoading = false;
	userError = false;
	userSaving = false;

	private router: Router = inject(Router);
	private toastrService: ToastrService = inject(ToastrService);
	private authService: AuthService = inject(AuthService);
	private usersService: UsersService = inject(UsersService);
	private modalService: NgbModal = inject(NgbModal);

	constructor() {
		this.userProfile$ = this.authService.getUserProfile$();
	}

	ngOnInit() {
		this.onLoadCurrentUser();

		window.scrollTo(0, 0);
	}

	async onLoadCurrentUser() {
		try {
			this.userLoading = true;
			this.userError = false;

			this.user = await this.usersService.getCurrentUser();
		} catch (error) {
			this.userError = true;
		} finally {
			this.userLoading = false;
		}
	}

	async onSaveUserChange(event: InlineEditSaveEvent, property: keyof UserModel) {
		setTimeout(() => {
			event.savedFunc?.(true);
		}, 500);

		try {
			await this.updateUser({
				...this.user,
				[property]: !!event.updatedValue ? event.updatedValue : null,
			} as UserModel);

			event.savedFunc?.(true);
		} catch (error: any) {
			event.savedFunc?.(false);
			this.toastrService.error('Please try again.', `Failed to Save Changes to ${property}`);
		}
	}

	private async updateUser(user: UserModel) {
		try {
			this.userSaving = true;

			this.user = await this.usersService.updateUser(user);

			this.toastrService.success(`User has been updated.`);
		} finally {
			this.userSaving = false;
		}
	}

	onSignOut() {
		try {
			this.authService.logout();
			this.router.navigate(['/login']);
		} catch (error) {
			console.error(error);
			this.toastrService.error('Unexpected sign out error. Please try again.');
		}
	}

	changePassword() {
		const modalRef = this.modalService.open(ChangePasswordModal);

		modalRef.componentInstance.userID = this.user?.userId!;
	}
}
