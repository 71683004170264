<div class="container pt-4">
	<h1 class="mt-3 mb-4">
		Approvals
		@if (approvalsTotal !== null) { <span class="text-secondary">({{ approvalsTotal | number }})</span> }
	</h1>

	<!-- filters -->
	<div class="row gx-3 mb-4">
		<div class="col-sm-10">
			<ng-select labelForId="leagueSelect" name="leagueSelect" [class.fw-bold]="filters.leagueID"
				[(ngModel)]="filters.leagueID" [loading]="leaguesLookupLoading" notFoundText="No matching leagues"
				[clearable]="false" (change)="onLoadApprovals(true)"
				[readonly]="leaguesLookupLoading || approvalsLoading">
				<ng-option [value]="0">All Leagues</ng-option>
				@for (league of (leaguesLookup$ | async); track league.id) {
				<ng-option [value]="league.id">{{ league.name }}</ng-option>
				}
			</ng-select>
		</div>
		<div class="col-auto">
			<select id="teamSelect" name="teamSelect" class="form-select" [class.fw-bold]="filters.teamID"
				aria-label="team filter" [(ngModel)]="filters.teamID" (change)="onLoadApprovals(true)"
				[disabled]="teamsLookupLoading || approvalsLoading">
				<option [ngValue]="null" selected>All Teams</option>
				@for (team of teamsLookup; track team.teamId) {
				<option [ngValue]="team.teamId">{{ team.teamName }}</option>
				}
			</select>
		</div>
		<div class="col-auto">
			<select id="statusSelect" name="statusSelect" class="form-select" [class.fw-bold]="filters.documentStatusID"
				aria-label="document status filter" [(ngModel)]="filters.documentStatusID"
				(change)="onLoadApprovals(true)" [disabled]="documentStatusesLookupLoading || approvalsLoading">
				<option [ngValue]="null" selected>All Statuses</option>
				@for (status of (documentStatusesLookup$ | async); track status.id) {
				<option [ngValue]="status.id">{{ status.name }}</option>
				}
			</select>
		</div>
		<div class="col-auto">
			<select id="subjectSelect" name="subjectSelect" class="form-select"
				[class.fw-bold]="filters.documentSubjectID" aria-label="document entity filter"
				[(ngModel)]="filters.documentSubjectID" (change)="onLoadApprovals(true)"
				[disabled]="documentSubjectsLookupLoading || approvalsLoading">
				<option [ngValue]="null" selected>All Entities</option>
				@for (subject of (documentSubjectsLookup$ | async); track subject.id) {
				<option [ngValue]="subject.id">{{ subject.name }}</option>
				}
			</select>
		</div>
	</div>

	<!-- listing -->
	@if (approvalsLoading && currentPage === 1) {
	<div class="border border-secondary-subtle">
		<app-table-placeholder [columnsCount]="6" [rowsCount]="4" />
	</div>
	} @else if (approvalsError && currentPage === 1) {
	<app-error message="Error loading document approvals" (retry)="onLoadApprovals(true)" />
	} @else if (!approvals.length) {
	<div class="card">
		<div class="card-body"><app-empty message="No document approvals matching your search criteria" />
		</div>
	</div>
	} @else {
	<div infiniteScroll (scrolled)="onLoadApprovals(false)">
		<div class="table-responsive">
			<table class="table table-hover table-bordered align-middle mb-0">
				<thead class="table-secondary">
					<tr>
						<th scope="col">League/Team/Player/Coach</th>
						<th class="text-center" scope="col">Submitted On</th>
						<th scope="col">Submitted by</th>
						<th scope="col">Document Type</th>
						<th scope="col">Status</th>
						<th class="text-center" scope="col">ACTIONS</th>
					</tr>
				</thead>
				<tbody>
					@for (approval of approvals; track approval.documentSubmissionID) {
					<tr>
						<td>
							<div class="d-flex align-items-center gap-1 flex-wrap">
								@switch (approval.documentSubjectId) {
								@case (DocumentSubjectCodes.LeagueDocument) {
								<!-- stretched-link -->
								<h5 class="mb-0">
									<app-league-icon class="me-2" />
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId]">{{ approval.leagueName }}</a>
								</h5>
								} @case (DocumentSubjectCodes.TeamDocument) {
								<h5 class="mb-0"><app-team-icon class="me-1" /> <a
										class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId, 'teams', approval.teamId]"
										[queryParams]="{ leagueName: approval.leagueName }">{{
										approval.teamName }}</a></h5>
								<h6 class="mb-0 fw-normal text-secondary"> - <a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId]">{{ approval.leagueName }}</a>
								</h6>
								} @case (DocumentSubjectCodes.PlayerDocument) {
								<h5 class="mb-0"><app-player-icon class="me-1" /> {{ approval.playerName }}</h5>
								<h6 class="mb-0 fw-normal text-secondary"> - <a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId, 'teams', approval.teamId]"
										[queryParams]="{ leagueName: approval.leagueName }">{{
										approval.teamName }}</a></h6>
								<h6 class="mb-0 fw-normal text-secondary"> - <a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId]">{{ approval.leagueName }}</a>
								</h6>
								} @case (DocumentSubjectCodes.CoachDocument) {
								<h5 class="mb-0"><app-coach-icon class="me-1" /> {{ approval.coachName }}</h5>
								<h6 class="mb-0 fw-normal text-secondary"> - <a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId, 'teams', approval.teamId]"
										[queryParams]="{ leagueName: approval.leagueName }">{{
										approval.teamName }}</a></h6>
								<h6 class="mb-0 fw-normal text-secondary"> - <a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', approval.leagueId]">{{ approval.leagueName }}</a>
								</h6>
								} @default {
								<h5 class="mb-0">???</h5>
								}
								}
							</div>
						</td>
						<td class="text-center">
							{{ approval.uploadedDateTime| date:'M/dd/yyyy' }}
						</td>
						<td>
							{{ approval.uploaderName }}
						</td>
						<td>
							{{ approval.documentName }}
						</td>
						<td>
							<app-status-icon class="small"
								[statusType]="DocumentSubjectStatusTypeMapping[approval.documentSubjectId]"
								[statusCode]="approval.statusId" [statusName]="approval.statusName"
								[showStatusName]="true" />
						</td>
						<td class="text-center">
							@if (approval.documentSubjectId !== DocumentSubjectCodes.LeagueDocument) {
							<app-document-actions [leagueID]="approval.leagueId" [leagueName]="approval.leagueName"
								[teamID]="approval.teamId" [teamName]="approval.teamName" [coachID]="approval.coachId"
								[coachName]="approval.coachName" [teamPlayerID]="approval.playerTeamId"
								[teamPlayerName]="approval.playerName" [viewFileID]="approval.uploadedFileId"
								[viewSubmissionID]="approval.documentSubmissionID"
								[viewFileType]="approval.uploadedFileType" [viewFileName]="approval.documentName"
								[showIntake]="false" [showUpload]="false" buttonSizeClass="btn-sm"
								(approved)="onApprovedOrRejected($event)" (rejected)="onApprovedOrRejected($event)" />
							} @else {
							@if (approval.documentId === LeagueDocumentTypeCodes.AffiliationApplication) {
							<a class="btn btn-sm btn-outline-dark"
								[routerLink]="['/leagues/', approval.leagueId, 'apps', 'affiliation', approval.documentSubmissionID, 'view']"
								ngbTooltip="View Affiliation Application"><app-view-icon /></a>
							} @else if (approval.documentId === LeagueDocumentTypeCodes.TournamentApplication) {
							<a class="btn btn-sm btn-outline-dark"
								[routerLink]="['/leagues/', approval.leagueId, 'apps', 'tournament', approval.documentSubmissionID, 'view']"
								ngbTooltip="View Tournament Application"><app-view-icon /></a>
							}
							}
						</td>
					</tr>
					}
				</tbody>
				@if ((approvalsLoading || approvalsError) && currentPage > 1) {
				<tfoot>
					<tr>
						<td colspan="6">
							@if (approvalsLoading) {
							<app-table-placeholder [columnsCount]="6" [rowsCount]="4" />
							}
							@if (approvalsError) {
							<app-error message="Error loading more approvals" (retry)="onLoadApprovals(true)" />
							}
						</td>
					</tr>
				</tfoot>
				}
			</table>
		</div>
	</div>
	}

	<div class="my-5"></div>
</div>
