import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationStart, NavigationEnd, CanActivateFn } from '@angular/router';

import { map, filter, defaultIfEmpty, catchError, of, finalize } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AppLoadingService } from '../services/app-loader.service';
import { AuthService, GlobalFiltersService, LookupService } from '../../shared/services';
import { LeagueYearLookupView } from '../../shared/models';
import { LookupCodes } from '../../constants';

export const configCanActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const router = inject(Router);
	const toastrService = inject(ToastrService);
	const appLoadingService = inject(AppLoadingService);
	const authService = inject(AuthService);
	const globalFiltersService = inject(GlobalFiltersService);
	const lookupService = inject(LookupService);

	console.log('configCanActivate - initializing global filters...');

	// clear app loading on navigation start/end to ensure it doesn't get stuck and
	//	always gets cleared when navigation is complete
	router.events.pipe(
		filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
	).subscribe(event => {
		console.log('configCanActivate - navigation start', event);
		appLoadingService.setAppLoading(false);
	});

	// proceed if league year is set
	if (globalFiltersService.getLeagueYearID()) {
		console.log('configCanActivate - already initialized');
		return true;
	}

	// TODO: ADD CHECK FOR AUTH AND DONT TRY INTIAILIZING IF NOT AUTHENTICATED
	if (!authService.isAuthenticated()) {
		console.log('configCanActivate - not authenticated - skipping initialization (back to login)');

		authService.redirectUrl = state.url;
		router.navigateByUrl('/login');
		return false;
	}

	console.log('configCanActivate - not initialized, fetching configuration (league years)...');

	// indicate that the app is initializing
	appLoadingService.setAppLoading(true);

	// fetch league years and set global filter defaults
	return lookupService
		.getLookup<LeagueYearLookupView>(LookupCodes.LeagueYears)
		.pipe(
			// TODO: ??? NEED THIS ???
			// defaultIfEmpty(undefined), // provide a default value if the observable is empty
			map(leagueYears => {
				console.log('configCanActivate - fetched configuration', leagueYears);

				// find current year and return to login if can't find one
				const currentYear = leagueYears?.find(ly => ly.current) || leagueYears?.[leagueYears?.length - 1];
				if (!currentYear) {
					console.log('configCanActivate - cannot find current year');

					// toastrService.error('Unexpected error initializing RBI. Please try refreshing the page.', 'Failed to Initialize');

					authService.redirectUrl = state.url;
					router.navigateByUrl('/login');
					return false;
				}

				console.log('configCanActivate - setting current year', currentYear);
				// set global filter defaults
				globalFiltersService.setLeagueYear(currentYear.id, currentYear.name);
				return true;
			}),
			catchError(err => {
				console.log('configCanActivate - error fetching configuration', err);
				// toastrService.error('Unexpected error initializing RBI. Please try refreshing the page.', 'Failed to Initialize');

				authService.redirectUrl = state.url;
				router.navigateByUrl('/login');
				return of(false);
			}),
			finalize(() => {
				// router.events.pipe(
				// 	filter(event => event instanceof NavigationStart)
				// ).subscribe(event => {
				// 	console.log('configCanActivate - navigation start', event);
				// 	appLoadingService.setAppLoading(false);
				// });
				// appLoadingService.setAppLoading(false);
			})
		);
};
