<header class="sticky-top">
	<!-- TODO: FIGURE OUT WHY NEED TO ADD bg-white TO MAKE NON-TRANSPARENT -->
	<nav class="navbar navbar-expand-lg bg-white shadow">
		<div class="container">
			<!-- mobile-only left-side items -->
			<div class="d-flex d-lg-none me-auto">
				<ul class="navbar-nav">
					<li class="nav-item">
						<a class="nav-link" routerLink="/search"><fa-icon [icon]="searchIcon"></fa-icon></a>
					</li>
				</ul>
			</div>
			<!-- <div class="collapse navbar-collapse">

			</div> -->

			<!-- desktop-only left-side menu items -->
			<div id="menuLeft" class="collapse navbar-collapse me-auto">
				<!-- mb-2 mb-lg-0 -->
				<ul class="navbar-nav ms-n1">
					@if (authService.hasPermission(AppPermissionCodes.Home_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/home">Home</a>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Leagues_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/leagues">Leagues</a>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Forum_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/forum">Forum</a>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Approvals_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/approvals">Approvals</a>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Resources_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/resources">Resources</a>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Reports_Execute)) {
					<li class="nav-item dropdown" routerLinkActive="active" ngbDropdown>
						<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
							aria-expanded="false" ngbDropdownToggle>
							Reports
						</a>
						<ul class="dropdown-menu" ngbDropdownMenu>
							@if (authService.hasPermission(AppPermissionCodes.Reports_TeamDocuments_View)) {
							<li ngbDropdownItem><a class="dropdown-item" routerLink="/reports/team-documents">Team
									Documents</a></li>
							}
							<!-- TODO: IMPLEMENT REPORT -->
							<!-- @if (authService.hasPermission(AppPermissionCodes.Reports_Insurance_View)) {
							<li ngbDropdownItem><a class="dropdown-item" routerLink="/reports/insurance">Insurance</a>
							</li>
							} -->
							@if (authService.hasPermission(AppPermissionCodes.Reports_LeagueContactSheets_View)) {
							<li ngbDropdownItem><a class="dropdown-item" routerLink="/reports/league-contacts">League
									Contacts</a></li>
							}
							@if (authService.hasPermission(AppPermissionCodes.Reports_GeneralParticipation_View)) {
							<li ngbDropdownItem><a class="dropdown-item"
									routerLink="/reports/general-participation">General
									Participation</a></li>
							}
							@if (authService.hasPermission(AppPermissionCodes.Reports_LeagueParticipation_View)) {
							<li ngbDropdownItem><a class="dropdown-item"
									routerLink="/reports/league-participation">League Participation</a>
							</li>
							}
							<!-- TODO: IMPLEMENT REPORT -->
							<!-- @if (authService.hasPermission(AppPermissionCodes.Reports_LocationCoverage_View)) {
							<li ngbDropdownItem><a class="dropdown-item"
									routerLink="/reports/location-coverage">Location Coverage</a></li>
							}
							@if (authService.hasPermission(AppPermissionCodes.Reports_LoginStatistics_View)) {
							<li ngbDropdownItem><a class="dropdown-item" routerLink="/reports/login-metrics">Login
									Metrics</a></li>
							} -->
						</ul>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Search_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/search"><fa-icon [icon]="searchIcon"></fa-icon></a>
					</li>
					}
				</ul>
			</div>

			<!-- desktop/mobile mlb rpm logo -->
			<a class="navbar-brand" routerLink="/">
				<img src="/assets/images/logo-horizontal.svg?cache=1" alt="MLB RPM logo" height="40">
			</a>

			<!-- desktop-only right-side menu items -->
			<div id="menuRight" class="collapse navbar-collapse flex-grow-0 ms-auto">
				<!-- TODO: MOVE THESE INPUTS INTO GLOBALFILTERS COMPONENT -->
				<form class="mx-2">
					<div class="row g-3 align-items-center">
						<div class="col-auto">
							<label for="leagueYearSelect" class="col-form-label fw-medium">League year</label>
						</div>
						<div class="col-auto">
							<select id="leagueYearSelect" name="leagueYearSelect" class="form-select fw-bold"
								aria-label="league year"
								[ngClass]="{ 'border-warning bg-warning-subtle': !selectedLeagueYear()?.current }"
								[(ngModel)]="selectedLeagueYear">
								@for (ly of (leagueYearsLookup$ | async); track ly.id) {
								<option [class.fw-bold]="ly.current" [class.text-danger]="!ly.current" [ngValue]="ly">{{
									ly.name
									}}</option>
								}
							</select>
						</div>
					</div>
				</form>

				<ul class="navbar-nav">
					@if (authService.hasPermission(AppPermissionCodes.Maintenance_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/settings"><fa-icon [icon]="settingsIcon"></fa-icon></a>
					</li>
					}
					@if (authService.hasPermission(AppPermissionCodes.Profile_Execute)) {
					<li class="nav-item" routerLinkActive="active">
						<a id="userProfile" class="nav-link bg-light rounded-circle text-center mx-1"
							routerLink="/profile">{{ userInitials ?? '??' }}</a>
					</li>
					}
				</ul>
			</div>

			<!-- mobile-only right-side menu toggle -->
			<!-- data-bs-toggle="collapse" data-bs-target="#menuLeft" -->
			<button class="navbar-toggler bg-secondary-subtle" type="button" aria-controls="menuLeft"
				aria-expanded="false" aria-label="Toggle menu" (click)="onShowMenu()">
				<!-- <span class="navbar-toggler-icon"></span> -->
				Menu
			</button>
		</div>
	</nav>
</header>
