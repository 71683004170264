<div class="modal-header">
	<img src="/assets/images/logo-horizontal.svg" alt="MLB RPM logo" height="30">
	<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
	@if (authService.hasPermission(AppPermissionCodes.Home_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/home"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Home</h5>
		<app-details-icon />
	</div>
	<hr />
	}
	@if (authService.hasPermission(AppPermissionCodes.Leagues_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/leagues"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Leagues</h5>
		<app-details-icon />
	</div>
	<hr />
	}
	@if (authService.hasPermission(AppPermissionCodes.Forum_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/forum"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Forum</h5>
		<app-details-icon />
	</div>
	<hr />
	}
	@if (authService.hasPermission(AppPermissionCodes.Approvals_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/approvals"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Approvals</h5>
		<app-details-icon />
	</div>
	<hr />
	}
	@if (authService.hasPermission(AppPermissionCodes.Resources_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/resources"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Resources</h5>
		<app-details-icon />
	</div>
	<hr />
	}

	@if (authService.hasPermission(AppPermissionCodes.Reports_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center">
		<h5>Reports</h5>
		<!-- <app-details-icon /> -->
	</div>
	<div class="ms-3 mt-2">
		@if (authService.hasPermission(AppPermissionCodes.Reports_TeamDocuments_View)) {
		<div class="menu-item mb-2 d-flex justify-content-between align-items-center"
			routerLink="/reports/team-documents" routerLinkActive="active" (click)="activeModal.dismiss()">
			<h6>Team Documents</h6>
			<app-details-icon />
		</div>
		<!-- <hr /> -->
		}
		<!-- TODO: IMPLEMENT REPORT -->
<!--		@if (authService.hasPermission(AppPermissionCodes.Reports_Insurance_View)) {-->
<!--		<div class="menu-item mb-2 d-flex justify-content-between align-items-center" routerLink="/reports/insurance"-->
<!--			routerLinkActive="active" (click)="activeModal.dismiss()">-->
<!--			<h6>Insurance</h6>-->
<!--			<app-details-icon />-->
<!--		</div>-->
		<!-- <hr /> -->
<!--		}-->
		@if (authService.hasPermission(AppPermissionCodes.Reports_LeagueContactSheets_View)) {
		<div class="menu-item mb-2 d-flex justify-content-between align-items-center"
			routerLink="/reports/league-contacts" routerLinkActive="active" (click)="activeModal.dismiss()">
			<h6>League Contacts</h6>
			<app-details-icon />
		</div>
		<!-- <hr /> -->
		}
		@if (authService.hasPermission(AppPermissionCodes.Reports_GeneralParticipation_View)) {
		<div class="menu-item mb-2 d-flex justify-content-between align-items-center"
			routerLink="/reports/general-participation" routerLinkActive="active" (click)="activeModal.dismiss()">
			<h6>General Participation</h6>
			<app-details-icon />
		</div>
		<!-- <hr /> -->
		}
		@if (authService.hasPermission(AppPermissionCodes.Reports_LeagueParticipation_View)) {
		<div class="menu-item mb-2 d-flex justify-content-between align-items-center"
			routerLink="/reports/league-participation" routerLinkActive="active" (click)="activeModal.dismiss()">
			<h6>League Participation</h6>
			<app-details-icon />
		</div>
		<!-- <hr /> -->
		}
		<!-- TODO: IMPLEMENT REPORT -->
<!--		@if (authService.hasPermission(AppPermissionCodes.Reports_LocationCoverage_View)) {-->
<!--		<div class="menu-item mb-2 d-flex justify-content-between align-items-center"-->
<!--			routerLink="/reports/location-coverage" routerLinkActive="active" (click)="activeModal.dismiss()">-->
<!--			<h6>Location Coverage</h6>-->
<!--			<app-details-icon />-->
<!--		</div>-->
<!--		&lt;!&ndash; <hr /> &ndash;&gt;-->
<!--		}-->
<!--		@if (authService.hasPermission(AppPermissionCodes.Reports_LoginStatistics_View)) {-->
<!--		<div class="menu-item mb-2 d-flex justify-content-between align-items-center"-->
<!--			routerLink="/reports/login-metrics" routerLinkActive="active" (click)="activeModal.dismiss()">-->
<!--			<h6>Login Metrics</h6>-->
<!--			<app-details-icon />-->
<!--		</div>-->
<!--		}-->
	</div>
	<hr />
	}

	@if (authService.hasPermission(AppPermissionCodes.Search_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/search"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Search</h5>
		<app-details-icon />
	</div>
	<hr />
	}
	@if (authService.hasPermission(AppPermissionCodes.Maintenance_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/settings"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Settings</h5>
		<app-details-icon />
	</div>
	<hr />
	}
	@if (authService.hasPermission(AppPermissionCodes.Profile_Execute)) {
	<div class="menu-item d-flex justify-content-between align-items-center" routerLink="/profile"
		routerLinkActive="active" (click)="activeModal.dismiss()">
		<h5>Profile ({{ userInitials ?? '??' }})</h5>
		<app-details-icon />
	</div>
	<hr />
	}

	<div class="row g-3 align-items-center">
		<div class="col-auto">
			<label for="leagueYearSelect" class="col-form-label fw-medium">League year</label>
		</div>
		<div class="col-auto">
			<select id="leagueYearSelect" name="leagueYearSelect" class="form-select fw-bold" aria-label="league year"
				[ngClass]="{ 'border-warning bg-warning-subtle': !selectedLeagueYear()?.current }"
				[(ngModel)]="selectedLeagueYear" (ngModelChange)="activeModal.dismiss()">
				@for (ly of (leagueYearsLookup$ | async); track ly.id) {
				<option [class.fw-bold]="ly.current" [class.text-danger]="!ly.current" [ngValue]="ly">{{
					ly.name
					}}</option>
				}
			</select>
		</div>
	</div>

	<hr />

	<div class="menu-item text-warning d-flex justify-content-between align-items-center mt-3">
		<h5 class="fw-medium" (click)="onSignOut()">Sign Out</h5>
		<!-- <app-details-icon /> -->
	</div>
</div>
<!-- <div class="modal-footer">
	<button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div> -->
