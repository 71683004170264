import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';

import { GlobalFiltersService } from '../shared/services/global-filters.service';

import { AnnouncementsComponent } from '../shared/components/data/announcements/announcements.component';
import { LeaguesComponent } from '../shared/components/data/leagues/leagues.component';
import { ForumComponent } from '../shared/components/data/forum/forum.component';

@Component({
	selector: 'app-home-page',
	templateUrl: './home.page.html',
	styleUrl: './home.page.scss',
	standalone: true,
	imports: [
		CommonModule, AnnouncementsComponent, LeaguesComponent, ForumComponent
	]
})
export class HomePage implements OnInit {
	globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	ngOnInit() {
	}
}
