import { Component, OnInit } from '@angular/core';

import { AnnouncementsComponent } from '../shared/components/data/announcements/announcements.component';
import { ForumComponent } from '../shared/components/data/forum/forum.component';

@Component({
	selector: 'app-forum-page',
	templateUrl: './forum.page.html',
	styleUrl: './forum.page.scss',
	standalone: true,
	imports: [
		AnnouncementsComponent, ForumComponent
	]
})
export class ForumPage implements OnInit {
	ngOnInit() {
		window.scrollTo(0, 0);
	}
}
