import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { configCanActivate } from './core/guards/config.guard';
import { authCanActivate } from './core/guards/auth.guard';

import { LoginPage } from './login/login.page';
import { NotFoundPage } from './not-found/not-found.page';

import { LoggedInHostPage } from './core/pages/logged-in-host/logged-in-host.page';

import { HomePage } from './home/home.page';

import { DocumentIntakePage } from './document-intake/document-intake.page';
import { DocumentViewerPage } from './document-viewer/document-viewer.page';

import { ForumPage } from './forum/forum.page';
import { PostEditPage } from './post-edit/post-edit.page';

import { ApprovalsPage } from './approvals/approvals.page';

import { SearchPage } from './search/search.page';

import { ProfilePage } from './profile/profile.page';

import { AppPermissionCodes } from './constants';

// empty component to use as a placeholder for routes opened in a modal
@Component({ template: '' })
class ModalPlaceholderComponent { }

export const ROUTES: Routes = [
	// , canActivate: [authCanActivate]
	{ path: 'login', component: LoginPage, title: 'Log In - MLB RPM' },
	{
		path: 'not-found',
		component: NotFoundPage,
		title: 'Not Found - MLB RPM',
	},

	{
		path: '',
		component: LoggedInHostPage,
		children: [
			{
				path: 'home',
				component: HomePage,
				title: 'Home - MLB RPM',
				data: { permissions: [AppPermissionCodes.Home_Execute] },
			},

			{
				path: 'document-intake',
				component: DocumentIntakePage,
				title: 'Document Intake - MLB RPM',
				data: { permissions: [AppPermissionCodes.League_Read] },
			},
			{
				path: 'document-viewer/:documentID',
				component: DocumentViewerPage,
				title: 'Document Viewer - MLB RPM',
				data: { permissions: [AppPermissionCodes.League_Read] },
			},

			{
				path: 'leagues',
				loadChildren: () =>
					import('./modules/leagues/routing').then((m) => m.ROUTES),
				// data: { permissions: [AppPermissionCodes.Leagues_Execute] }
			},

			{
				path: 'forum',
				component: ForumPage,
				title: 'Forum - MLB RPM',
				data: { permissions: [AppPermissionCodes.Forum_Execute] },
			},
			{
				path: 'forum/post/edit',
				component: PostEditPage,
				title: 'Post Edit - Forum - MLB RPM',
				data: { permissions: [AppPermissionCodes.Forum_Execute] },
			},
			{
				path: 'approvals',
				component: ApprovalsPage,
				title: 'Approvals - MLB RPM',
				data: { permissions: [AppPermissionCodes.Approvals_Execute] },
			},
			{
				path: 'reports',
				loadChildren: () =>
					import('./modules/reports/routing').then((m) => m.ROUTES),
				data: { permissions: [AppPermissionCodes.Reports_Execute] },
			},
			{
				path: 'resources',
				loadChildren: () =>
					import('./modules/resources/routing').then((m) => m.ROUTES),
				data: { permissions: [AppPermissionCodes.Resources_Execute] },
			},
			{
				path: 'search',
				component: SearchPage,
				title: 'Search - MLB RPM',
				data: { permissions: [AppPermissionCodes.Search_Execute] },
			},

			{
				path: 'settings',
				loadChildren: () =>
					import('./modules/settings/routing').then((m) => m.ROUTES),
				data: { permissions: [AppPermissionCodes.Maintenance_Execute] },
			},

			{
				path: 'profile',
				component: ProfilePage,
				title: 'Profile - MLB RPM',
				data: { permissions: [AppPermissionCodes.Profile_Execute] },
			},

			{ path: '**', redirectTo: '/home' },
		],
		canActivate: [authCanActivate, configCanActivate],
		canActivateChild: [authCanActivate, configCanActivate],
	},

	{ path: '**', redirectTo: '/home' },
];
