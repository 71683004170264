import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';

@Component({
	standalone: true,
	imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
	template: `
		<app-header></app-header>
		<main class="main mb-5">
			<router-outlet></router-outlet>
		</main>
		<app-footer></app-footer>
	`
})
export class LoggedInHostPage {

}
