<div class="container pt-4 pt-sm-4">
	<!-- title -->
	<div class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-2 mb-3">
		<h1 class="mb-0">Profile</h1>
		<div class="hstack flex-column flex-md-row align-items-md-center gap-2">
			<button class="btn btn-lg btn-outline-dark w-100 w-sm-auto" (click)="changePassword()">Change Password</button>
			<button class="btn btn-lg btn-primary w-100 w-sm-auto" (click)="onSignOut()">
				<fa-icon class="me-2" [icon]="signoutIcon" /> Sign Out
			</button>
		</div>
	</div>

	<!-- profile -->
	<div class="row mb-5">
		<div class="col">
			@if (userLoading) {
			<app-card-placeholder [hasHeader]="true" [bodyRowItemCounts]="[[5],[1],[2]]" />
			} @else if (userError) {
			<app-error message="Error loading user profile" (retry)="onLoadCurrentUser()" />
			} @else if (user) {
			<div class="card">
				<div class="card-header py-2">
					<h3 class="my-1 hstack justify-content-md-between gap-2 flex-wrap">
						<div>
							<fa-icon class="me-2" [icon]="profileIcon" />
							<span>{{ user.firstName }} {{ user.lastName }}</span>
							<span class="badge text-bg-light ms-2">{{ user.roleName }}</span>
						</div>
						<span class="badge" [class.text-bg-success]="user.active"
							[class.text-bg-secondary]="!user.active">{{ user.active ? "Active" : "Inactive" }}</span>
					</h3>
				</div>
				<div class="card-body border-bottom">
					<div class="row gy-3">
						<div class="col-auto">
							<label class="form-label" for="firstNameField">
								First name
							</label>

							<app-inline-edit id="firstNameField" name="firstNameField" [value]="user.firstName"
								[is-text]="true" (save)="onSaveUserChange($event, 'firstName')">
							</app-inline-edit>
						</div>
						<div class="col-auto">
							<label class="form-label" for="lastNameField">
								Last name
							</label>

							<app-inline-edit id="lastNameField" name="lastNameField" [value]="user.lastName"
								[is-text]="true" (save)="onSaveUserChange($event, 'lastName')">
							</app-inline-edit>
						</div>
						<div class="col-auto">
							<label class="form-label" for="workPhoneField">
								Work phone
							</label>

							<app-inline-edit id="workPhoneField" name="workPhoneField" [value]="user.workPhone"
								[is-phone]="true" (save)="onSaveUserChange($event, 'workPhone')">
							</app-inline-edit>
						</div>
						<div class="col-auto">
							<label class="form-label" for="mobilePhoneField">
								Mobile phone
							</label>

							<app-inline-edit id="mobilePhoneField" name="mobilePhoneField" [value]="user.mobilePhone"
								[is-phone]="true" (save)="onSaveUserChange($event, 'mobilePhone')">
							</app-inline-edit>
						</div>
						<div class="col-auto">
							<label class="form-label" for="emailField">
								Email address
							</label>

							<app-inline-edit id="emailField" name="emailField" type="email" [value]="user.email"
								[is-text]="true" (save)="onSaveUserChange($event, 'email')">
							</app-inline-edit>
						</div>
					</div>
				</div>
				<div class="card-body border-bottom">
					<div class="row g-5">
						<div class="col-auto">
							<label class="form-label" for="adminByRegionsField">
								Administered by/visible in regions
							</label>

							<div id="adminByRegionsField">
								<!-- {{ user. }} -->
								TODO: ADD REGION NAMES TO THE API PAYLOAD
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="row g-5">
						<div class="col-auto">
							<label class="form-label" for="directorLeaguesField">
								Director for leagues (full access)
							</label>

							<div id="directorLeaguesField">
								@if (user.fullAccessLeagues?.length) {
								@for (league of user.fullAccessLeagues; track league.id) {
								<div class="badge text-bg-light me-2">{{ league.name }}</div>
								}
								} @else {
								<span class="text-secondary">None</span>
								}
							</div>
						</div>
						<div class="col-auto">
							<label class="form-label" for="guestLeaguesField">
								Guest director for leagues (limited access)
							</label>

							<div id="guestLeaguesField">
								@if (user.guestAccessLeagues?.length) {
								@for (league of user.guestAccessLeagues; track league.id) {
								<div class="badge text-bg-light me-2">{{ league.name }}</div>
								}
								} @else {
								<span class="text-secondary">None</span>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <pre>{{ user | json }}</pre> -->
			}
		</div>
	</div>

	<!-- <div class="row mb-5">
		<div class="col-sm-10">
			<app-object-viewer [object]="userProfile$ | async" [deep]="true"></app-object-viewer>
		</div>
	</div> -->
</div>
