import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Observable, Subscription, skip, catchError, tap, throwError } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';

import {
	SpinnerComponent,
	TablePlaceholderComponent,
	ErrorComponent,
	EmptyComponent,
	LeagueIconComponent,
	TeamIconComponent,
	PlayerIconComponent,
	CoachIconComponent,
	ViewIconComponent,
	ApproveIconComponent,
	RejectIconComponent,
	StatusIconComponent, StatusTypes,
	DocumentActionsComponent
} from '@app/shared/components';
import { GlobalFiltersService, LookupService, ApprovalsService } from '@app/shared/services';
import { LookupView, TeamListViewModel, PagedModel, EntityDocumentViewModel } from '@app/shared/models';
import { LookupCodes, Messages, DocumentStatusCodes, DocumentSubjectCodes, LeagueDocumentTypeCodes } from '@app/constants';

interface ApprovalsFilters {
	leagueID: number | null;
	teamID: number | null;
	documentStatusID: number | null;
	documentSubjectID: number | null;
}

@Component({
	selector: 'app-approvals-page',
	templateUrl: './approvals.page.html',
	styleUrl: './approvals.page.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule, FormsModule,
		FontAwesomeModule, NgbTooltipModule, InfiniteScrollModule, NgSelectModule,
		SpinnerComponent, TablePlaceholderComponent, ErrorComponent, EmptyComponent,
		LeagueIconComponent, TeamIconComponent, PlayerIconComponent, CoachIconComponent,
		ViewIconComponent, ApproveIconComponent, RejectIconComponent, StatusIconComponent,
		DocumentActionsComponent
	]
})
export class ApprovalsPage implements OnInit, OnDestroy {
	// internal state
	filters: ApprovalsFilters = {
		leagueID: 0,
		teamID: null,
		documentStatusID: DocumentStatusCodes.InProgress,
		documentSubjectID: null
	};
	get isFiltersEmpty() {
		return Object.values(this.filters).every(f => f === null);
	}

	approvals: EntityDocumentViewModel[] = [];
	approvalsLoading = false;
	approvalsError = false;
	approvalsTotal: number | null = null;
	currentPage = 1;

	private leagueYearSubscription: Subscription | null = null;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	private lookupService: LookupService = inject(LookupService);
	private approvalsService: ApprovalsService = inject(ApprovalsService);

	// lookups
	leaguesLookup$!: Observable<LookupView[]>;
	leaguesLookupLoading = true;

	teamsLookup: TeamListViewModel[] | null = null;
	teamsLookupLoading = true;

	documentStatusesLookup$!: Observable<LookupView[]>;
	documentStatusesLookupLoading = true;

	documentSubjectsLookup$!: Observable<LookupView[]>;
	documentSubjectsLookupLoading = true;

	// icons

	// constants
	DocumentStatusCodes = DocumentStatusCodes;
	DocumentSubjectCodes = DocumentSubjectCodes;
	LeagueDocumentTypeCodes = LeagueDocumentTypeCodes;
	// mapping from documentsubjectcodes to StatusTypes
	DocumentSubjectStatusTypeMapping: { [key: number]: StatusTypes } = {
		[DocumentSubjectCodes.PlayerDocument]: StatusTypes.Document,
		[DocumentSubjectCodes.CoachDocument]: StatusTypes.Document,
		[DocumentSubjectCodes.TeamDocument]: StatusTypes.Document,
		[DocumentSubjectCodes.LeagueDocument]: StatusTypes.AffiliationApp,
		[DocumentSubjectCodes.OtherDocument]: StatusTypes.Document
	};

	constructor() {
		this.initializeLookups();
	}

	ngOnInit() {
		this.leagueYearSubscription = this.globalFiltersService.getLeagueYear$()
			.pipe(skip(1)) // skip the first value, which is the initial value
			.subscribe(() => this.onLoadApprovals(true));
		this.onLoadApprovals(true);

		// scroll to top
		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		this.leagueYearSubscription?.unsubscribe();
	}

	private initializeLookups() {
		this.leaguesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Leagues)
			.pipe(
				tap(() => setTimeout(() => this.leaguesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.leaguesLookupLoading = false, 0);
					return this.showLookupErrorToast('Leagues', err);
				})
			);

		this.documentStatusesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.DocumentStatuses)
			.pipe(
				tap(() => setTimeout(() => this.documentStatusesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.documentStatusesLookupLoading = false, 0);
					return this.showLookupErrorToast('Document Statuses', err);
				})
			);

		this.documentSubjectsLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.DocumentSubjects)
			.pipe(
				tap(() => setTimeout(() => this.documentSubjectsLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.documentSubjectsLookupLoading = false, 0);
					return this.showLookupErrorToast('Document Subjects', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	private resetApprovalsResults() {
		this.currentPage = 1;
		this.approvals = [];
		this.approvalsTotal = null;
	}

	async onLoadApprovals(reset: boolean) {
		if (reset) {
			this.resetApprovalsResults();
		}

		try {
			this.approvalsLoading = true;
			this.approvalsError = false;

			const response = await this.approvalsService.getApprovals(
				this.filters.leagueID,
				this.filters.teamID,
				this.filters.documentStatusID,
				this.filters.documentSubjectID,
				this.currentPage
			);

			this.processApprovalsPageData(response);
		} catch (error) {
			this.approvalsError = true;
		} finally {
			this.approvalsLoading = false;
		}
	}

	private processApprovalsPageData(response: PagedModel<EntityDocumentViewModel> | null) {
		if (!response) {
			return;
		}

		if (!this.approvals) {
			this.approvals = [];
		}

		this.approvals.push(...response.pageData.filter(a => this.approvals.findIndex(aa => aa.documentSubmissionID === a.documentSubmissionID) === -1));
		this.approvalsTotal = response.totalRecords || this.approvals.length;
		if (response.pageData.length > 0) {
			this.currentPage++;
		}
	}

	onApprovedOrRejected(approval: EntityDocumentViewModel) {
		const index = this.approvals.findIndex(a => a.documentSubmissionID === approval.documentSubmissionID);
		if (index !== -1) {
			this.approvals.splice(index, 1);
		}
	}
}
