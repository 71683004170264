<div class="container pt-4">
	<div class="card border-2">
		<div class="card-body">
			<div class="row g-3 mb-3">
				<div class="col">
					<input class="form-control form-control-lg form-control-search input-search" type="search"
						autocomplete="noautocomplete" placeholder="Search leagues, teams, players, coaches..."
						[ngModel]="searchText" (ngModelChange)="searchTextChange.next($event)" />
				</div>
				<div class="col-sm-auto">
					<select class="form-select form-select-lg" [(ngModel)]="leagueYearId" (ngModelChange)="onLoadSearchResults(true)">
						<option [ngValue]="null">Any Year</option>
						@for (ly of (leagueYearsLookup$ | async); track ly.id) {
						<option [ngValue]="ly.id">{{ ly.name }}</option>
						}
					</select>
				</div>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="checkbox" id="league" [(ngModel)]="includeLeagues"
					(ngModelChange)="onLoadSearchResults(true)" />
				<label class="form-check-label" for="league">Leagues</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="checkbox" id="team" [(ngModel)]="includeTeams"
					(ngModelChange)="onLoadSearchResults(true)" />
				<label class="form-check-label" for="team">Teams</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="checkbox" id="player" [(ngModel)]="includePlayers"
					(ngModelChange)="onLoadSearchResults(true)" />
				<label class="form-check-label" for="player">Players</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="checkbox" id="coach" [(ngModel)]="includeCoaches"
					(ngModelChange)="onLoadSearchResults(true)" />
				<label class="form-check-label" for="coach">Coaches</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="checkbox" id="document" [(ngModel)]="includeDocuments"
					(ngModelChange)="onLoadSearchResults(true)" />
				<label class="form-check-label" for="document">Documents</label>
			</div>
			<!--			<div class="row mt-3">-->
			<!--				<span class="text-secondary">-->
			<!--					Search through all information you have access to within the system (players, coaches, teams, leagues, etc)-->
			<!--				</span>-->
			<!--			</div>-->
		</div>
	</div>

	<div class="mt-4">
		@if (searchLoading && pageIndex === 1) {
		<div class="border border-secondary-subtle">
			<app-table-placeholder [columnsCount]="4" [rowsCount]="4" />
		</div>
		} @else if (searchError && pageIndex === 1) {
		<app-error [message]="'Error loading search results'" (retry)="onLoadSearchResults(true)" />
		} @else if (searchResults && !searchResults.length) {
		<div class="card">
			<div class="card-body">
				<app-empty message="No results matching your search criteria" />
			</div>
		</div>
		} @else if (searchResults) {
		<div infiniteScroll (scrolled)="onLoadSearchResults(false)">
			<table class="table table-hover table-bordered align-middle mb-0">
				<thead class="table-secondary">
					<tr>
						<th scope="col">Search Results <span class="text-secondary">({{resultTotal || '0'}})</span></th>
						<th class="text-center year-col" scope="col">Year</th>
						<th class="text-center d-none d-lg-table-cell" scope="col">Document Status</th>
						<th class="d-none d-lg-table-cell" scope="col">Details</th>
					</tr>
				</thead>
				<tbody>
					@for (result of searchResults; track result.leagueId || result.teamId || result.playerTeamId ||
					result.coachId) {
					<tr>
						<td>
							<div class="d-flex align-items-center gap-1 flex-wrap">
								@switch (result.entityType) {
								@case (EntityTypeNames.League) {
								<h5 class="mb-0">
									<app-league-icon class="me-2" />
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId]">
										{{ result.leagueName }} @if(result.orgName) { - {{ result.orgName }} }
									</a>
								</h5>
								} @case (EntityTypeNames.Team) {
								<h5 class="mb-0">
									<app-team-icon class="me-1" />
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId, 'teams', result.teamId]"
										[queryParams]="{ leagueName: result.leagueName }">{{ result.teamName }}</a>
								</h5>
								<h6 class="mb-0 fw-normal text-secondary">
									-
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId]">{{ result.leagueName }}</a>
								</h6>
								} @case (EntityTypeNames.Player) {
								<h5 class="mb-0"><app-player-icon class="me-1" /> {{ result.playerName }}</h5>
								<h6 class="mb-0 fw-normal text-secondary">
									-
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId, 'teams', result.teamId]"
										[queryParams]="{ leagueName: result.leagueName }">
										{{ result.teamName }}
									</a>
								</h6>
								<h6 class="mb-0 fw-normal">
									-
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId]">
										{{ result.leagueName }}
									</a>
								</h6>
								} @case (EntityTypeNames.Coach) {
								<h5 class="mb-0"><app-coach-icon class="me-1" /> {{ result.coachName }}</h5>
								<h6 class="mb-0 fw-normal text-secondary">
									-
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId, 'teams', result.teamId]"
										[queryParams]="{ leagueName: result.leagueName }">
										{{ result.teamName }}
									</a>
								</h6>
								<h6 class="mb-0 fw-normal">
									-
									<a class="text-reset text-decoration-none"
										[routerLink]="['/leagues/', result.leagueId]">
										{{ result.leagueName }}
									</a>
								</h6>
								}
								<!-- todo Document -->
								<!--									@case (EntityTypeNames.Document) {-->
								<!--								<h5 class="mb-0"><app-doc-icon class="me-1" /> {{ result.documentStatusNotes }}</h5>-->
								<!--								<h6 class="mb-0 fw-normal text-secondary"> - {{ result.playerName}}</h6>-->
								<!--								<h6 class="mb-0 fw-normal text-secondary">-->
								<!--									- -->
								<!--									<a-->
								<!--										class="text-reset text-decoration-none"-->
								<!--										[routerLink]="['/leagues/', result.leagueId, 'teams', result.teamId]"-->
								<!--										[queryParams]="{ leagueName: result.leagueName }"-->
								<!--									>-->
								<!--										{{ result.teamName }}-->
								<!--									</a>-->
								<!--								</h6>-->
								<!--								<h6 class="mb-0 fw-normal text-secondary">-->
								<!--									- -->
								<!--									<a class="text-reset text-decoration-none" [routerLink]="['/leagues/', result.leagueId]">-->
								<!--										{{ result.leagueName }}-->
								<!--									</a>-->
								<!--								</h6>-->
								<!--								}-->
								@default {
								<h5 class="mb-0">???</h5>
								} }
							</div>
						</td>
						<td class="text-center">{{ result.leagueYear }}</td>
						<td class="d-none d-lg-table-cell">
							@if(result.documentStatusId !== null){
							<app-status-icon [statusType]="StatusTypes.AffiliationApp"
								[statusCode]="result.documentStatusId" [statusName]="result.documentStatusName"
								[showStatusName]="true" />
							}
						</td>
						<td class="d-none d-lg-table-cell">
							@if(result.city) { {{ result.city }}, } @if(result.stateName) { {{ result.stateName }}, }
							@if(result.countryName) { {{ result.countryName }} }
						</td>
					</tr>
					}
				</tbody>

				@if ((searchLoading || searchError) && pageIndex > 1) {
				<tfoot>
					<tr>
						<td colspan="6">
							@if (searchLoading) {
							<app-table-placeholder [columnsCount]="4" [rowsCount]="4" />
							} @if (searchError) {
							<app-error message="Error loading more search results"
								(retry)="onLoadSearchResults(false)" />
							}
						</td>
					</tr>
				</tfoot>
				}
			</table>
		</div>
		}
	</div>
</div>
