import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root',
})
export class ModalRoutingService {
	private location: Location = inject(Location);
	private router: Router = inject(Router);
	private route: ActivatedRoute = inject(ActivatedRoute);
	private modalService: NgbModal = inject(NgbModal);

	constructor() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.openModalForRoute();
			}
		});
	}

	private openModalForRoute() {
		const routeData: any = this.getDeepRouteData(this.route);
		if (routeData?.modal) {
			// const modalComponent = this.getModalComponent(routeData.modal);
			this.modalService.open(routeData?.modal, { fullscreen: true }).result
				.then(result => this.location.back(), () => this.location.back());
		}
	}

	private getDeepRouteData(route: ActivatedRoute) {
		let currentRoute = route.snapshot;
		while (currentRoute) {
			if (currentRoute.firstChild) {
				currentRoute = currentRoute.firstChild;
			} else {
				return currentRoute.data;
			}
		}
		return null;
	}

	// private getModalComponent(modalIdentifier: string): any {
	// 	// Implement logic to return the modal component class based on the identifier
	// 	// This could be a simple switch statement or a mapping object
	// }
}
